import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { Title } from "utils/Title";
import PageMainHeader from "components/PageMainHeader";
import { AuthContext } from "contexts/AuthContext";
import { SettingsContext } from "contexts/SettingsContext";
import Banner from "@leafygreen-ui/banner";
import { Tabs, Tab } from "@leafygreen-ui/tabs";
import _ from "lodash";
import { errorHandler } from "utils/errorHandler";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { axiosInstance as axios } from "configs/axiosConfig";
import mongoose from "mongoose";
import { FaPlusSquare, FaTimes } from "react-icons/fa";
import IconButton from "@leafygreen-ui/icon-button";
import { useDebouncedCallback } from "use-debounce";
import Tooltip from "@leafygreen-ui/tooltip";
import { servicesInRush } from "utils/servicesInRush";

const title = "Settings";

const getTags = () => {
  console.log("* getTags init");
  const storedTags = window.localStorage.getItem("_tags");
  // console.log("- storedSettings: ", storedSettings);
  if (storedTags) return JSON.parse(storedTags);
  return new Promise((resolve, reject) => {
    const url = `/ship-station/tags`;
    axios
      .get(url)
      .then((res) => {
        // console.log("- res", res);
        const tags = res.data;
        window.localStorage.setItem("_tags", JSON.stringify(tags));
        resolve(tags);
      })
      .catch((error) => {
        let retval = errorHandler(error);
        reject(retval);
      });
    // resolve([])
  });
};

const orderStatusOptions = [
  "awaiting_shipment",
  "awaiting_payment",
  "pending_fulfillment",
  "shipped",
  "on_hold",
  "cancelled",
];

const binAssignmentTypes = [
  { value: "order", name: "Order Base(default)" },
  { value: "capacity", name: "Bin's Load Capacity" },
  // { value: "hanger", name: "Hanger" },
];

const pretreatmentModels = [
  { label: "Schulze Pretreatmaker4", value: "Schulze_Pretreatmaker4" },
  { label: "Viper MAXX", value: "Viper MAXX" },
];

const speedColorObj = { speed: "", color: "" };

const integratedAutomations = [
  { value: "KOTHARI", name: "Kothari Automation" },
  { value: "GTXCMD", name: "Brother GTX CMD Line" },
  { value: "CADLINK", name: "CADlink Digital Factory" },
];

const printingMethods = [
  { value: "DTG", name: "Direct-to-garment(DTG)" },
  { value: "DTF", name: "Direct-to-film(DTF)" },
];

const initialPlatens = [
  {
    name: "Large Platen(GTX H21)",
    code: "16x21",
    size: { width: 16, height: 21 },
    sizeCodeGTX: 0,
    printers: ["GTX"],
  },
  {
    name: "Large Platen",
    code: "16x20",
    size: { width: 16, height: 20 },
    sizeCodeGTX: null,
    printers: ["Epson"],
  },
  {
    name: "Large Platen(GTX H18)",
    code: "16x18",
    size: { width: 16, height: 18 },
    sizeCodeGTX: 1,
    printers: ["GTX"],
  },
  {
    name: "Medium Platen",
    code: "14x16",
    size: { width: 14, height: 16 },
    sizeCodeGTX: 2,
    printers: ["Epson", "GTX"],
  },
  {
    name: "Small Platen",
    code: "10x12",
    size: { width: 10, height: 12 },
    sizeCodeGTX: 3,
    printers: ["Epson", "GTX"],
  },
  {
    name: "Extra Small Platen",
    code: "7x8",
    size: { width: 7, height: 8 },
    sizeCodeGTX: 4,
    printers: ["Epson", "GTX"],
  },
  {
    name: "Sleeve Platen",
    code: "4x4",
    size: { width: 4, height: 4 },
    sizeCodeGTX: null,
    printers: ["Epson"],
  },
];

const initialServiceCodeInRush = servicesInRush

const initialCustomPlatenConfigure = {
  standardPlaten: "",
  customPlaten: "",
  valueToDown: 0,
};

const initialWorkOrderStatus = { name: "", color: "" };

const mediaTypes = [
  { value: "roll", name: "Roll" },
  { value: "sheet", name: "Sheet" },
];

const orderMgts = [
  { value: 'shipstation', name: "ShipStation"},
  { value: 'manual', name: "Manual Order"}
]

const cadlinkIntegratedCompanies = ['swag vintage', 'kite and crest', '365 printing', 'the merch hall', 'all american', 'aspen & company']

export default function Settings() {
  const { settings } = React.useContext(SettingsContext);
  const { user } = React.useContext(AuthContext);
  const [selectedTab, setSelectedTab] = React.useState(0);

  const [configures, setConfigures] = React.useState([]);
  const queryClient = useQueryClient();
  let {
    data: tags,
    isError,
    error,
  } = useQuery("tags", getTags, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10,
  });

  React.useEffect(() => {
    if (settings) {
      if (!Boolean(settings?.pretreatmentConfigures?.speedColor.length)) {
        settings.pretreatmentConfigures.speedColor.push(speedColorObj);
      }
      if (!Boolean(settings?.platens.length)) {
        settings.platens = initialPlatens;
        mutateSettings.mutate({
          condition: { id: settings._id },
          update: { platens: initialPlatens },
        });
      }
      if (!settings?.serviceCodesInRush || (settings?.serviceCodesInRush && !Boolean(settings?.serviceCodesInRush.length))) {
        settings.serviceCodesInRush = initialServiceCodeInRush;
        mutateSettings.mutate({
          condition: { id: settings._id },
          update: { serviceCodesInRush: initialServiceCodeInRush },
        });
      }
      if (
        settings?.useCustomPlatens &&
        !Boolean(settings?.customPlatenConfigures?.length)
      ) {
        settings.customPlatenConfigures.push(initialCustomPlatenConfigure);
      }

      if (
        settings?.useWorkOrderStatus &&
        !Boolean(settings?.workOrderStatuses?.length)
      ) {
        settings.workOrderStatuses.push(initialWorkOrderStatus);
      }
      setConfigures(settings);
    }
    // eslint-disable-next-line
  }, [settings]);

  const onChangeHandler = useDebouncedCallback(({ id, value }) => {
    console.log("== onChangeHandler init");

    console.log("- id, value: ", id, value);
    let update;

    if (_.includes(id, ".")) {
      let parentKey = id.split(".")[0],
        childKey = id.split(".")[1];
      setConfigures({
        ...configures,
        [parentKey]: { ...configures[parentKey], [childKey]: value },
      });
      update = { [parentKey]: { ...configures[parentKey], [childKey]: value } };
      console.log("- update: ", update);
      update &&
        mutateSettings.mutate({
          condition: { id: configures._id },
          update,
        });
      return;
    }
    if (_.includes(id, "pretreatment")) {
      let keyIndexArr = id.split("_");
      console.log("- keyIndexArr: ", keyIndexArr);
      setConfigures((current) => {
        console.log("- current: ", current);
        current.pretreatmentConfigures.speedColor[keyIndexArr[2]][
          keyIndexArr[1]
        ] = value.toUpperCase();
        return { ...current };
      });
      update = { pretreatmentConfigures: configures.pretreatmentConfigures };
      console.log("- update: ", update);
      update &&
        mutateSettings.mutate({
          condition: { id: configures._id },
          update,
        });
      return;
    }
    if (_.includes(id, "workorderstatus")) {
      let keyIndexArr = id.split("_");
      console.log("- keyIndexArr: ", keyIndexArr);
      setConfigures((current) => {
        console.log("- current: ", current);
        current.workOrderStatuses[keyIndexArr[2]][keyIndexArr[1]] = value;
        return { ...current };
      });
      update = { workOrderStatuses: configures.workOrderStatuses };
      console.log("- update: ", update);
      update &&
        mutateSettings.mutate({
          condition: { id: configures._id },
          update,
        });
      return;
    }
    if (_.includes(id, "keyName")) {
      let keyIndexArr = id.split("_");
      console.log("- keyName keyIndexArr: ", keyIndexArr);
      setConfigures((current) => {
        console.log("- current: ", current);
        current.itemOptionKeys[keyIndexArr[1]] = value;
        return { ...current };
      });
      update = { itemOptionKeys: configures.itemOptionKeys };
      console.log("- update: ", update);
      update &&
        mutateSettings.mutate({
          condition: { id: configures._id },
          update,
        });
      return;
    }

    if (_.includes(id, "platen_")) {
      let keyIndexArr = id.split("_");
      // console.log("- platen keyIndexArr: ", keyIndexArr);
      setConfigures((current) => {
        console.log("- current: ", current.platens);
        if (keyIndexArr.length === 4) {
          current.platens[keyIndexArr[3]][keyIndexArr[1]][keyIndexArr[2]] =
            value;
        } else {
          if(keyIndexArr[1]==='printers') {
            current.platens[keyIndexArr[2]][keyIndexArr[1]] = [value];
          } else {
            current.platens[keyIndexArr[2]][keyIndexArr[1]] = value;
          }
        }
        return { ...current };
      });
      update = { platens: configures.platens };
      // console.log("- update: ", update.platens);
      update &&
        mutateSettings.mutate({
          condition: { id: configures._id },
          update,
        });
      return;
    }

    if (_.includes(id, "customPlaten_")) {
      let keyIndexArr = id.split("_");
      console.log("- customPlaten keyIndexArr: ", keyIndexArr);
      setConfigures((current) => {
        console.log("- current: ", current.customPlatenConfigures);
        current.customPlatenConfigures[keyIndexArr[2]][keyIndexArr[1]] = value;
        return { ...current };
      });
      update = { customPlatenConfigures: configures.customPlatenConfigures };
      console.log("- update: ", update.customPlatenConfigures);
      update &&
        mutateSettings.mutate({
          condition: { id: configures._id },
          update,
        });
      return;
    }

    if (_.includes(id, "media_")) {
      let keyIndexArr = id.split("_");
      console.log("- media keyIndexArr: ", keyIndexArr);
      setConfigures((current) => {
        console.log("- current: ", current.media);
        if (keyIndexArr.length === 4) {
          current.media[keyIndexArr[3]][keyIndexArr[1]][keyIndexArr[2]] = value;
        } else {
          current.media[keyIndexArr[2]][keyIndexArr[1]] = value;
        }
        return { ...current };
      });
      update = { media: configures.media };
      console.log("- update: ", update.media);
      update &&
        mutateSettings.mutate({
          condition: { id: configures._id },
          update,
        });
      return;
    }
    
    if (_.includes(id, "cadLinkQueues_")) {
      let keyIndexArr = id.split("_");
      console.log("- cadLinkQueues keyIndexArr: ", keyIndexArr);
      setConfigures((current) => {
        console.log("- current: ", current.cadLinkQueues);
        if(keyIndexArr[1]==='hostnames') {
          if(_.includes(value, ',')) value = value.replace(" ", "").split(',');
        }
        current.cadLinkQueues[keyIndexArr[2]][keyIndexArr[1]] = value;
        return { ...current };
      });
      update = { cadLinkQueues: configures.cadLinkQueues };
      console.log("- update: ", update.cadLinkQueues);
      update &&
        mutateSettings.mutate({
          condition: { id: configures._id },
          update,
        });
      return;
    }

    if (_.includes(id, "cadLinkPrintModes_")) {
      let keyIndexArr = id.split("_");
      console.log("- cadLinkPrintModes keyIndexArr: ", keyIndexArr);
      setConfigures((current) => {
        console.log("- current: ", current.cadLinkPrintModes);
        current.cadLinkPrintModes[keyIndexArr[2]][keyIndexArr[1]] = value;
        return { ...current };
      });
      update = { cadLinkPrintModes: configures.cadLinkPrintModes };
      console.log("- update: ", update.cadLinkPrintModes);
      update &&
        mutateSettings.mutate({
          condition: { id: configures._id },
          update,
        });
      return;
    }

    if (_.includes(id, "serviceCodesInRush_")) {
      let keyIndexArr = id.split("_");
      console.log("- serviceCodesInRush keyIndexArr: ", keyIndexArr);
      setConfigures((current) => {
        console.log("- current: ", current.serviceCodesInRush);
        current.serviceCodesInRush[keyIndexArr[2]][keyIndexArr[1]] = value;
        return { ...current };
      });
      update = { serviceCodesInRush: configures.serviceCodesInRush };
      console.log("- update: ", update.serviceCodesInRush);
      update &&
        mutateSettings.mutate({
          condition: { id: configures._id },
          update,
        });
      return;
    }

    setConfigures({ ...configures, [id]: value });
    update = { [id]: value };
    console.log("- update: ", update);
    update &&
      mutateSettings.mutate({
        condition: { id: configures._id },
        update,
      });
  }, 1000);

  const mutateSettings = useMutation(
    async (payload) => {
      console.log("* mutateSettings init");
      const url = `/settings/update`;
      return await axios.post(url, payload);
    },
    {
      onError: (error) => {
        let retval = errorHandler(error);
        toast.warning(retval, {
          position: "bottom-right",
        });
      },
      onSuccess: (response) => {
        // console.log("- response: ", response)
        if(response?.data) {
          window.localStorage.setItem('_settings', JSON.stringify(response.data))
        }
        toast.info("Updated", {
          position: "bottom-right",
          autoClose: 1000,
          pauseOnHover: false,
        });

        queryClient.invalidateQueries("settings");
      },
    }
  );

  // const handleSubmit = async () => {
  //   console.log("* handleSubmit init")
  //   // console.log("- configures: ", configures);
  //   mutateSettings.mutate({
  //     condition: { id: configures._id },
  //     update: configures
  //   })
  // };

  const speedColorHandler = ({ flag, index }) => {
    console.log("* speedColorAddHandler init");
    console.log("- flag: ", flag);
    console.log("- index: ", index);
    setConfigures((current) => {
      if (flag) {
        current.pretreatmentConfigures.speedColor.push({
          ...speedColorObj,
          _id: mongoose.Types.ObjectId(),
        });
      } else {
        let confirm = window.confirm("Confirm to remove the mapping data!");
        if (confirm) {
          current.pretreatmentConfigures.speedColor.splice(index, 1);
          mutateSettings.mutate({
            condition: { id: configures._id },
            update: { pretreatmentConfigures: current.pretreatmentConfigures },
          });
        }
      }
      return { ...current };
    });
  };

  const optionKeyHandler = ({ flag, index }) => {
    console.log("* OptionKeyHandler init");
    console.log("- flag: ", flag);
    console.log("- index: ", index);
    setConfigures((current) => {
      if (flag) {
        current.itemOptionKeys.push("");
      } else {
        let confirm = window.confirm("Confirm to remove the key name!");
        if (confirm) current.itemOptionKeys.splice(index, 1);

        mutateSettings.mutate({
          condition: { id: configures._id },
          update: { itemOptionKeys: current.itemOptionKeys },
        });
      }
      return { ...current };
    });
  };

  const workOrderStatusesHandler = ({ flag, index }) => {
    console.log("* workOrderStatusesHandler init");
    console.log("- flag: ", flag);
    console.log("- index: ", index);
    setConfigures((current) => {
      if (flag) {
        current.workOrderStatuses.push(initialWorkOrderStatus);
      } else {
        let confirm = window.confirm("Confirm to remove the status!");
        if (confirm) current.workOrderStatuses.splice(index, 1);
        mutateSettings.mutate({
          condition: { id: configures._id },
          update: { workOrderStatuses: current.workOrderStatuses },
        });
      }
      return { ...current };
    });
  };

  const platensHandler = ({ flag, index }) => {
    console.log("* platensHandler init");
    console.log("- flag: ", flag);
    console.log("- index: ", index);
    setConfigures((current) => {
      if (flag) {
        current.platens.push({
          code: "",
          name: "",
          size: { width: "", height: "" },
          printers: [],
          sizeCodeGTX: "",
        });
      } else {
        let confirm = window.confirm("Confirm to remove the platen!");
        if (confirm) current.platens.splice(index, 1);
        mutateSettings.mutate({
          condition: { id: configures._id },
          update: { platens: current.platens },
        });
      }
      console.log(current);

      return { ...current };
    });
  };

  const customPlatensHandler = ({ flag, index }) => {
    console.log("* customPlatensHandler init");
    console.log("- flag: ", flag);
    console.log("- index: ", index);
    setConfigures((current) => {
      if (flag) {
        current.customPlatenConfigures.push(initialCustomPlatenConfigure);
      } else {
        let confirm = window.confirm("Confirm to remove the custom platen!");
        if (confirm) current.customPlatenConfigures.splice(index, 1);
        mutateSettings.mutate({
          condition: { id: configures._id },
          update: { customPlatenConfigures: current.customPlatenConfigures },
        });
      }
      console.log(current);

      return { ...current };
    });
  };

  const mediaHandler = ({ flag, index }) => {
    console.log("* mediaHandler init");
    console.log("- flag: ", flag);
    console.log("- index: ", index);
    setConfigures((current) => {
      if (flag) {
        current.media.push({
          code: "",
          name: "",
          size: { width: "", height: "" },
          type: "roll",
        });
      } else {
        let confirm = window.confirm("Confirm to remove the medium!");
        if (confirm) current.media.splice(index, 1);
        mutateSettings.mutate({
          condition: { id: configures._id },
          update: { media: current.media },
        });
      }
      // console.log(current);

      return { ...current };
    });
  };

  const cadLinkQueuesHandler = ({ flag, index }) => {
    console.log("* cadLinkQueuesHandler init");
    console.log("- flag: ", flag);
    console.log("- index: ", index);
    setConfigures((current) => {
      if (flag) {
        current.cadLinkQueues.push({
          name: "",
          hotFolder: "",
          printMode: "",
          hostnames: "",
        });
      } else {
        let confirm = window.confirm("Confirm to remove the queue!");
        if (confirm) current.cadLinkQueues.splice(index, 1);
        mutateSettings.mutate({
          condition: { id: configures._id },
          update: { cadLinkQueues: current.cadLinkQueues },
        });
      }
      // console.log(current);

      return { ...current };
    });
  };

  const cadLinkPrintModesHandler = ({ flag, index }) => {
    console.log("* cadLinkQueuesHandler init");
    console.log("- flag: ", flag);
    console.log("- index: ", index);
    setConfigures((current) => {
      if (flag) {
        current.cadLinkPrintModes.push({
          name: ""
        });
      } else {
        let confirm = window.confirm("Confirm to remove the print mode!");
        if (confirm) current.cadLinkPrintModes.splice(index, 1);
        mutateSettings.mutate({
          condition: { id: configures._id },
          update: { cadLinkPrintModes: current.cadLinkPrintModes },
        });
      }
      // console.log(current);

      return { ...current };
    });
  };

  const serviceCodesHandler = ({ flag, index }) => {
    console.log("* serviceCodesHandler init");
    console.log("- flag: ", flag);
    console.log("- index: ", index);
    setConfigures((current) => {
      if (flag) {
        current.serviceCodesInRush.push({
          name: "",
          symbol: "",
        });
      } else {
        let confirm = window.confirm("Confirm to remove the service code!");
        if (confirm) current.serviceCodesInRush.splice(index, 1);
        mutateSettings.mutate({
          condition: { id: configures._id },
          update: { serviceCodesInRush: current.serviceCodesInRush },
        });
      }
      // console.log(current);

      return { ...current };
    });
  };

  return (
    <>
      <Title title={title} />
      <PageMainHeader title={title} user={user} settings={settings} />
      <ToastContainer theme="dark" />
      <section className="primary settings">
        {isError && <Banner variant="danger">{error}</Banner>}
        <Tabs
          setSelected={setSelectedTab}
          selected={selectedTab}
          aria-label="Tabs for setting"
        >
          <Tab name="Basic Info">
            <div className="tab-wrapper p-10">
              <div className="input-field">
                <label htmlFor="company">Company</label>
                <input
                  type="text"
                  id="company"
                  defaultValue={configures?.company ? configures.company : ""}
                  onChange={(e) =>
                    onChangeHandler({ id: e.target.id, value: e.target.value })
                  }
                />
              </div>
              <div className="input-field">
                <label htmlFor="company">Timezone</label>
                <input
                  type="text"
                  id="timezone"
                  defaultValue={configures?.timezone ? configures.timezone : ""}
                  onChange={(e) =>
                    onChangeHandler({ id: e.target.id, value: e.target.value })
                  }
                />
              </div>
              <div className="input-field">
                <label htmlFor="defaultIntegratedAutomation">
                  Default Integrated Automation
                </label>
                <select
                  value={configures.defaultIntegratedAutomation}
                  onChange={(e) =>
                    onChangeHandler({ id: e.target.id, value: e.target.value })
                  }
                  id="defaultIntegratedAutomation"
                >
                  <option value="">== Select an option ==</option>
                  {integratedAutomations.map((i, index) => {
                    return (
                      <option value={i?.value} key={index}>
                        {i.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="input-field">
                <label htmlFor="defaultPrintingMethod">
                  Default Printing Method
                </label>
                <select
                  value={configures.defaultPrintingMethod}
                  onChange={(e) =>
                    onChangeHandler({ id: e.target.id, value: e.target.value })
                  }
                  id="defaultPrintingMethod"
                >
                  <option value="">== Select an option ==</option>
                  {printingMethods.map((p, index) => {
                    return (
                      <option value={p?.value} key={index}>
                        {p.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="input-field">
                <label htmlFor="defaultOrderManagement">
                  Default Order Management
                </label>
                <select
                  value={configures.defaultOrderManagement}
                  onChange={(e) =>
                    onChangeHandler({ id: e.target.id, value: e.target.value })
                  }
                  id="defaultOrderManagement"
                  disabled={user.username !== 'super'}
                >
                  <option value="">== Select an option ==</option>
                  {orderMgts.map((o, index) => {
                    return (
                      <option value={o?.value} key={index}>
                        {o.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="input-checkbox-container">
                <input
                  type="checkbox"
                  id="useInventoryModule"
                  defaultChecked={
                    configures?.useInventoryModule
                      ? configures.useInventoryModule
                      : false
                  }
                  onChange={(e) =>
                    onChangeHandler({
                      id: e.target.id,
                      value: e.currentTarget.checked,
                    })
                  }
                />
                <label htmlFor="useInventoryModule">Use Inventory Module</label>
              </div>
              {configures?.useInventoryModule && (
                <div className="input-checkbox-container">
                  <input
                    type="checkbox"
                    id="useNegativeStock"
                    defaultChecked={
                      configures?.useNegativeStock
                        ? configures.useNegativeStock
                        : false
                    }
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.currentTarget.checked,
                      })
                    }
                  />
                  <label htmlFor="useNegativeStock">
                    Leave negative current stock quantity for components
                  </label>
                </div>
              )}
              { user.username === "super" && (
                <>
                  <div className="input-checkbox-container">
                    <input
                      type="checkbox"
                      id="usePrintedFilm"
                      defaultChecked={
                        configures?.usePrintedFilm
                          ? configures.usePrintedFilm
                          : false
                      }
                      onChange={(e) =>
                        onChangeHandler({
                          id: e.target.id,
                          value: e.currentTarget.checked,
                        })
                      }
                    />
                    <label htmlFor="usePrintedFilm">
                      Manage inventory status for Printed Flims
                      <small>; super</small>
                    </label>
                  </div>
                  <div className="input-checkbox-container">
                    <input
                      type="checkbox"
                      id="useUnlockedPrintflo"
                      defaultChecked={
                        configures?.useUnlockedPrintflo
                          ? configures.useUnlockedPrintflo
                          : false
                      }
                      onChange={(e) =>
                        onChangeHandler({
                          id: e.target.id,
                          value: e.currentTarget.checked,
                        })
                      }
                    />
                    <label htmlFor="useUnlockedPrintflo">
                      Use unlocked edition of Printflo
                      <small>; super</small>
                    </label>
                  </div>
                  <div className="input-checkbox-container">
                    <input
                      type="checkbox"
                      id="useManualOrderCreation"
                      defaultChecked={
                        configures?.useManualOrderCreation
                          ? configures.useManualOrderCreation
                          : false
                      }
                      onChange={(e) =>
                        onChangeHandler({
                          id: e.target.id,
                          value: e.currentTarget.checked,
                        })
                      }
                    />
                    <label htmlFor="useManualOrderCreation">
                      Use manual order creation
                      <small>; super</small>
                    </label>
                  </div>
                </>
              )}
            </div>
          </Tab>
          <Tab name="Shipstation API">
            <div className="tab-wrapper p-10">
              <div className="input-field">
                <label htmlFor="ssApi.key">API Key</label>
                <input
                  type="text"
                  id="ssApi.key"
                  defaultValue={
                    configures?.ssApi?.key ? configures.ssApi.key : ""
                  }
                  onChange={(e) =>
                    onChangeHandler({ id: e.target.id, value: e.target.value })
                  }
                  style={{ width: "100%" }}
                />
              </div>
              <div className="input-field">
                <label htmlFor="ssApi.secret">API Secreat</label>
                <input
                  type="text"
                  id="ssApi.secret"
                  defaultValue={
                    configures?.ssApi?.secret ? configures.ssApi.secret : ""
                  }
                  onChange={(e) =>
                    onChangeHandler({ id: e.target.id, value: e.target.value })
                  }
                  style={{ width: "100%" }}
                />
              </div>
              <div className="card p-10">
                <span className="block mb-10 font-weight-bold">
                  Order list filter by
                </span>

                <div className="input-field">
                  <label htmlFor="orderStatus">Order Status</label>
                  <select
                    defaultValue={configures?.orderStatus}
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.target.value,
                      })
                    }
                    id="orderStatus"
                    disabled={user.username !== "super"}
                  >
                    {orderStatusOptions.map((status) => {
                      return (
                        <option value={status} key={status}>
                          {status}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="input-checkbox-container">
                  <input
                    type="checkbox"
                    checked={
                      configures?.useAwaitingPayment
                        ? configures.useAwaitingPayment
                        : false
                    }
                    id="useAwaitingPayment"
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.currentTarget.checked,
                      })
                    }
                  />
                  <label htmlFor="useAwaitingPayment">
                    Use Awaiting Payment
                  </label>
                  <br />
                  <small>An additional filter for sync orders</small>
                </div>

                <div className="input-field">
                  {tags && (
                    <>
                      <label htmlFor="accountTag.tagId">Tag</label>
                      <select
                        defaultValue={configures?.accountTag?.tagId}
                        onChange={(e) =>
                          onChangeHandler({
                            id: e.target.id,
                            value: e.target.value,
                          })
                        }
                        id="accountTag.tagId"
                      >
                        <option value="">== Select a tag ==</option>
                        {tags.map((tag) => {
                          return (
                            <option key={tag?.tagId} value={tag?.tagId}>
                              {tag.name}
                            </option>
                          );
                        })}
                      </select>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Tab>
          <Tab name="Artwork">
            <div className="tab-wrapper">
              <div className="card p-10">
                <span className="block mb-10 font-weight-bold">
                  Preferences of AWS S3
                </span>
                <div className="input-field">
                  <label htmlFor="aws.apiKey">API Key</label>
                  <input
                    type="text"
                    id="aws.apiKey"
                    defaultValue={
                      configures?.aws?.apiKey ? configures.aws.apiKey : ""
                    }
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.target.value,
                      })
                    }
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="input-field">
                  <label htmlFor="aws.apiSecret">API Secret</label>
                  <input
                    type="text"
                    id="aws.apiSecret"
                    defaultValue={
                      configures?.aws?.apiSecret ? configures.aws.apiSecret : ""
                    }
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.target.value,
                      })
                    }
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="input-field">
                  <label htmlFor="aws.region">Region</label>
                  <input
                    type="text"
                    id="aws.region"
                    defaultValue={
                      configures?.aws?.region ? configures.aws.region : ""
                    }
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="input-field">
                  <label htmlFor="aws.bucket">S3 Bucket</label>
                  <input
                    type="text"
                    id="aws.bucket"
                    defaultValue={
                      configures?.aws?.bucket ? configures.aws.bucket : ""
                    }
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="input-field">
                  <label htmlFor="aws.shipLabel">S3 Shipment Labels</label>
                  <input
                    type="text"
                    id="aws.shipLabel"
                    defaultValue={
                      configures?.aws?.shipLabel ? configures.aws.shipLabel : ""
                    }
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="input-field">
                  <label htmlFor="aws.graphics">S3 Graphics</label>
                  <input
                    type="text"
                    id="aws.graphics"
                    defaultValue={
                      configures?.aws?.graphics ? configures.aws.graphics : ""
                    }
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="card mt-10 p-10">
                <span className="block mb-10 font-weight-bold">
                  Preferences for local repository
                </span>
                <Banner variant="danger">
                  This option totally depends on local network environments and
                  requires to configure symlink for your local repository.
                </Banner>
                <div className="input-checkbox-container">
                  <input
                    type="checkbox"
                    checked={
                      configures?.useLocalRepository
                        ? configures.useLocalRepository
                        : false
                    }
                    id="useLocalRepository"
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.currentTarget.checked,
                      })
                    }
                  />
                  <label htmlFor="useLocalRepository">
                    Use a local repository
                  </label>
                </div>

                <div className="input-field">
                  <label htmlFor="graphicFilePath">Graphic File Path</label>
                  <input
                    type="text"
                    id="graphicFilePath"
                    defaultValue={
                      configures?.graphicFilePath
                        ? configures.graphicFilePath
                        : ""
                    }
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="input-field">
                  <label htmlFor="shipmentLabelPath">Shipment Label Path</label>
                  <input
                    type="text"
                    id="shipmentLabelPath"
                    defaultValue={
                      configures?.shipmentLabelPath
                        ? configures.shipmentLabelPath
                        : ""
                    }
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </Tab>
          <Tab name="Shipment">
            <div className="tab-wrapper">
              <div className="card p-10 mb-10">
                <div className="input-field">
                  <label htmlFor="binAssignmentType">
                    Bin System Assignment Type
                  </label>
                  <select
                    defaultValue={configures?.binAssignmentType}
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.target.value,
                      })
                    }
                    id="binAssignmentType"
                  >
                    {binAssignmentTypes.map((t) => {
                      return (
                        <option value={t.value} key={t.value}>
                          {t.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="input-field">
                  <label htmlFor="addFundsAmount">Amount($) of add funds</label>
                  <input
                    type="text"
                    id="addFundsAmount"
                    defaultValue={
                      configures?.addFundsAmount
                        ? configures.addFundsAmount
                        : ""
                    }
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="input-field">
                  {tags && (
                    <>
                      <label htmlFor="tagInRush.tagId">
                        Tag for In-Rush Shipping
                      </label>
                      <select
                        defaultValue={
                          configures?.tagInRush?.tagId
                            ? configures.tagInRush.tagId
                            : ""
                        }
                        onChange={(e) =>
                          onChangeHandler({
                            id: e.target.id,
                            value: e.target.value,
                          })
                        }
                        id="tagInRush.tagId"
                      >
                        <option value="" className="grey-text text-darken-3">
                          == Select a tag ==
                        </option>
                        {tags.map((tag) => {
                          return (
                            <option
                              key={tag?.tagId}
                              value={tag?.tagId}
                              className="grey-text text-darken-3"
                            >
                              {tag.name}
                            </option>
                          );
                        })}
                      </select>
                    </>
                  )}
                </div>
                <div className="input-checkbox-container">
                  <input
                    type="checkbox"
                    id="testLabel"
                    defaultChecked={
                      configures?.testLabel ? configures.testLabel : false
                    }
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.currentTarget.checked,
                      })
                    }
                  />
                  <label htmlFor="testLabel">
                    Test mode for shipping label
                  </label>
                </div>
                <div className="input-checkbox-container">
                  <input
                    type="checkbox"
                    id="markShipped"
                    defaultChecked={
                      configures?.printPackingSlip
                        ? configures.printPackingSlip
                        : false
                    }
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.currentTarget.checked,
                      })
                    }
                  />
                  <label htmlFor="markShipped">
                    Allow "Mark an Order as Shipped"
                  </label>
                </div>
                <div className="input-checkbox-container">
                  <input
                    type="checkbox"
                    id="printPackingSlip"
                    defaultChecked={
                      configures?.printPackingSlip
                        ? configures.printPackingSlip
                        : false
                    }
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.currentTarget.checked,
                      })
                    }
                  />
                  <label htmlFor="printPackingSlip">Print a packing slip</label>
                </div>
              </div>
              {configures?.printPackingSlip && user.username === "super" && (
                <div className="card">
                  <div className="packingslip-container">
                    <span className="block mb-10 font-weight-bold">
                      Packing slip template
                    </span>

                    <div className="input-checkbox-container">
                      <input
                        type="checkbox"
                        id="useCustomfieldsMsg"
                        defaultChecked={
                          configures?.useCustomfieldsMsg
                            ? configures.useCustomfieldsMsg
                            : false
                        }
                        onChange={(e) =>
                          onChangeHandler({
                            id: e.target.id,
                            value: e.currentTarget.checked,
                          })
                        }
                      />
                      <label htmlFor="useCustomfieldsMsg">
                        Use custom gift message
                      </label>
                    </div>
                    <div className="input-checkbox-container">
                      <input
                        type="checkbox"
                        id="useCustomerGuide"
                        defaultChecked={
                          configures?.useCustomerGuide
                            ? configures.useCustomerGuide
                            : false
                        }
                        onChange={(e) =>
                          onChangeHandler({
                            id: e.target.id,
                            value: e.currentTarget.checked,
                          })
                        }
                      />
                      <label htmlFor="useCustomerGuide">
                        Show QR code(embeded a link) for customer guide
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Tab>
          {user.username === "super" && (
            <Tab name="Platens">
              <div className="tab-wrapper">
                <div className="card">
                  <div className="platens-container">
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <span className="block font-weight-bold">Platens</span>
                      <Tooltip
                        align="left"
                        justify="start"
                        darkMode={true}
                        className="lg-tooltip-container"
                        trigger={
                          <IconButton
                            onClick={() => platensHandler({ flag: true })}
                            aria-label="Add a platen"
                          >
                            <FaPlusSquare size="1.5em" color="#09804C" />
                          </IconButton>
                        }
                      >
                        {" "}
                        Add a platen
                      </Tooltip>
                    </div>
                    {configures?.platens?.map((platen, index) => {
                      return (
                        <div key={index} className="platen-wrapper">
                          <div className="input-field">
                            <label htmlFor={`platen_code_${index}`}>Code</label>
                            <input
                              type="text"
                              id={`platen_code_${index}`}
                              defaultValue={platen?.code ? platen.code : ""}
                              onChange={(e) =>
                                onChangeHandler({
                                  id: e.target.id,
                                  value: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="input-field">
                            <label htmlFor={`platen_name_${index}`}>Name</label>
                            <input
                              type="text"
                              id={`platen_name_${index}`}
                              defaultValue={platen?.name ? platen.name : ""}
                              onChange={(e) =>
                                onChangeHandler({
                                  id: e.target.id,
                                  value: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="input-field">
                            <label htmlFor={`platen_size_width_${index}`}>
                              Width(inch)
                            </label>
                            <input
                              type="text"
                              id={`platen_size_width_${index}`}
                              defaultValue={
                                platen?.size.width ? platen.size.width : ""
                              }
                              onChange={(e) =>
                                onChangeHandler({
                                  id: e.target.id,
                                  value: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="input-field">
                            <label htmlFor={`platen_size_height_${index}`}>
                              Height(inch)
                            </label>
                            <input
                              type="text"
                              id={`platen_size_height_${index}`}
                              defaultValue={
                                platen?.size.height ? platen.size.height : ""
                              }
                              onChange={(e) =>
                                onChangeHandler({
                                  id: e.target.id,
                                  value: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="input-field">
                            <label htmlFor={`platen_printers_${index}`}>
                              Printers
                            </label>
                            <input
                              type="text"
                              id={`platen_printers_${index}`}
                              defaultValue={
                                (platen?.printers && Boolean(platen.printers.length))
                                  ? platen.printers.join(", ")
                                  : ""
                              }
                              onChange={(e) =>
                                onChangeHandler({
                                  id: e.target.id,
                                  value: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="input-field">
                            <label htmlFor={`platen_sizeCodeGTX_${index}`}>
                              GTX size code
                            </label>
                            <input
                              type="text"
                              id={`platen_sizeCodeGTX_${index}`}
                              defaultValue={
                                platen?.sizeCodeGTX ? platen.sizeCodeGTX : ""
                              }
                              onChange={(e) =>
                                onChangeHandler({
                                  id: e.target.id,
                                  value: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="d-flex justify-content-end align-items-center">
                            <Tooltip
                              align="bottom"
                              justify="middle"
                              className="lg-tooltip-container"
                              darkMode={true}
                              trigger={
                                <IconButton
                                  onClick={() =>
                                    platensHandler({ flag: false, index })
                                  }
                                  aria-label="Remove a platen"
                                >
                                  <FaTimes color="#d91b42" />
                                </IconButton>
                              }
                            >
                              Remove a platen
                            </Tooltip>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Tab>
          )}
          <Tab name="Queue Print">
            <div className="tab-wrapper">
              <div className="card p-10 mb-10">
                <div className="input-checkbox-container">
                  <input
                    type="checkbox"
                    id="platenChangeWarning"
                    defaultChecked={
                      configures?.platenChangeWarning
                        ? configures.platenChangeWarning
                        : false
                    }
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.currentTarget.checked,
                      })
                    }
                  />
                  <label htmlFor="platenChangeWarning">
                    Warning for changing a platen
                  </label>
                </div>
                <div className="input-checkbox-container">
                  <input
                    type="checkbox"
                    id="autoQueue"
                    defaultChecked={
                      configures?.autoQueue ? configures.autoQueue : false
                    }
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.currentTarget.checked,
                      })
                    }
                  />
                  <label htmlFor="autoQueue">Auto queue print</label>
                </div>
                <div className="input-checkbox-container">
                  <input
                    type="checkbox"
                    id="useCustomPlatens"
                    defaultChecked={
                      configures?.useCustomPlatens
                        ? configures.useCustomPlatens
                        : false
                    }
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.currentTarget.checked,
                      })
                    }
                  />
                  <label htmlFor="useCustomPlatens">Use custom platens</label>
                </div>
                {configures?.defaultIntegratedAutomation === "GTXCMD" && (
                  <div className="input-checkbox-container">
                    <input
                      type="checkbox"
                      id="useARXfile"
                      defaultChecked={
                        configures?.useARXfile ? configures.useARXfile : false
                      }
                      onChange={(e) =>
                        onChangeHandler({
                          id: e.target.id,
                          value: e.currentTarget.checked,
                        })
                      }
                    />
                    <label htmlFor="useARXfile">Use ARX file</label>
                  </div>
                )}
              </div>
              {configures?.useCustomPlatens && (
                <div className="card p-10">
                  <div className="custom-platens-container">
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <span className="block font-weight-bold">
                        Custom platen configures
                      </span>
                      <Tooltip
                        align="left"
                        justify="start"
                        darkMode={true}
                        className="lg-tooltip-container"
                        trigger={
                          <IconButton
                            onClick={() => customPlatensHandler({ flag: true })}
                            aria-label="Add a custom platen"
                          >
                            <FaPlusSquare size="1.5em" color="#09804C" />
                          </IconButton>
                        }
                      >
                        {" "}
                        Add a custom platen
                      </Tooltip>
                    </div>
                    {configures?.customPlatenConfigures?.map(
                      (configure, index) => {
                        return (
                          <div key={index} className="custom-platen-wrapper">
                            <div className="input-field">
                              <label
                                htmlFor={`customPlaten_standardPlaten_${index}`}
                              >
                                Standard platen
                              </label>
                              <input
                                type="text"
                                id={`customPlaten_standardPlaten_${index}`}
                                defaultValue={
                                  configure?.standardPlaten
                                    ? configure.standardPlaten
                                    : ""
                                }
                                onChange={(e) =>
                                  onChangeHandler({
                                    id: e.target.id,
                                    value: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="input-field">
                              <label
                                htmlFor={`customPlaten_customPlaten_${index}`}
                              >
                                Custom platen
                              </label>
                              <input
                                type="text"
                                id={`customPlaten_customPlaten_${index}`}
                                defaultValue={
                                  configure?.customPlaten
                                    ? configure.customPlaten
                                    : ""
                                }
                                onChange={(e) =>
                                  onChangeHandler({
                                    id: e.target.id,
                                    value: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="input-field">
                              <label
                                htmlFor={`customPlaten_valueToDown_${index}`}
                              >
                                Value(Y) to down
                              </label>
                              <input
                                type="text"
                                id={`customPlaten_valueToDown_${index}`}
                                defaultValue={
                                  configure?.valueToDown
                                    ? configure.valueToDown
                                    : ""
                                }
                                onChange={(e) =>
                                  onChangeHandler({
                                    id: e.target.id,
                                    value: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="d-flex justify-content-end align-items-center">
                              <Tooltip
                                align="right"
                                justify="start"
                                className="lg-tooltip-container"
                                darkMode={true}
                                trigger={
                                  <IconButton
                                    onClick={() =>
                                      customPlatensHandler({
                                        flag: false,
                                        index,
                                      })
                                    }
                                    aria-label="Remove a custom platen"
                                  >
                                    <FaTimes color="#d91b42" />
                                  </IconButton>
                                }
                              >
                                Remove a custom platen
                              </Tooltip>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              )}
            </div>
          </Tab>
          <Tab name="Label">
            <div className="tab-wrapper">
              <div className="card p-10 mb-10">
                <div className="input-checkbox-container">
                  <input
                    type="checkbox"
                    id="usePlatenSymbol"
                    defaultChecked={
                      configures?.usePlatenSymbol
                        ? configures.usePlatenSymbol
                        : false
                    }
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.currentTarget.checked,
                      })
                    }
                  />
                  <label htmlFor="usePlatenSymbol">Print a platen symbol</label>
                </div>
                <div className="input-checkbox-container">
                  <input
                    type="checkbox"
                    id="printDescriptionLabel"
                    defaultChecked={
                      configures?.printDescriptionLabel
                        ? configures.printDescriptionLabel
                        : false
                    }
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.currentTarget.checked,
                      })
                    }
                  />
                  <label htmlFor="printDescriptionLabel">
                    Print a description of item
                  </label>
                </div>
                <div className="input-checkbox-container">
                  <input
                    type="checkbox"
                    id="printLabelByGraphicPositions"
                    defaultChecked={
                      configures?.printLabelByGraphicPositions
                        ? configures.printLabelByGraphicPositions
                        : false
                    }
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.currentTarget.checked,
                      })
                    }
                  />
                  <label htmlFor="printLabelByGraphicPositions">
                    Print a label by graphic positions
                  </label>
                </div>
                <div className="input-checkbox-container">
                  <input
                    type="checkbox"
                    id="printOrderDateLabel"
                    defaultChecked={
                      configures?.printOrderDateLabel
                        ? configures.printOrderDateLabel
                        : false
                    }
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.currentTarget.checked,
                      })
                    }
                  />
                  <label htmlFor="printOrderDateLabel">
                    Print order date instead of sticker printed date
                  </label>
                </div>
              </div>

              <div className="card">
                  <div className="service-codes-container">
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <span className="block font-weight-bold">Service Codes in Rush</span>
                      <Tooltip
                        align="left"
                        justify="start"
                        darkMode={true}
                        className="lg-tooltip-container"
                        trigger={
                          <IconButton
                            onClick={() => serviceCodesHandler({flag: true})}
                            aria-label="Add a service code"
                          >
                            <FaPlusSquare size="1.5em" color="#09804C" />
                          </IconButton>
                        }
                      >
                        {" "}
                        Add a service code
                      </Tooltip>
                    </div>
                    <div className="service-codes-wrapper-container">
                      {configures?.serviceCodesInRush?.map((service, index) => {
                        return (
                          <div key={index} className="service-codes-wrapper">
                            <div className="input-field">
                              <label htmlFor={`serviceCodesInRush_name_${index}`}>Code</label>
                              <input
                                type="text"
                                id={`serviceCodesInRush_name_${index}`}
                                defaultValue={service?.name ? service.name : ""}
                                onChange={(e) =>
                                  onChangeHandler({
                                    id: e.target.id,
                                    value: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="input-field">
                              <label htmlFor={`serviceCodesInRush_symbol_${index}`}>Symbol</label>
                              <input
                                type="text"
                                id={`serviceCodesInRush_symbol_${index}`}
                                defaultValue={service?.symbol ? service.symbol : ""}
                                onChange={(e) =>
                                  onChangeHandler({
                                    id: e.target.id,
                                    value: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="d-flex justify-content-end align-items-center">
                              <Tooltip
                                align="bottom"
                                justify="middle"
                                className="lg-tooltip-container"
                                darkMode={true}
                                trigger={
                                  <IconButton
                                    onClick={() => serviceCodesHandler({flag: false, index})}
                                    aria-label="Remove a service code"
                                  >
                                    <FaTimes color="#d91b42" />
                                  </IconButton>
                                }
                              >
                                Remove a service code
                              </Tooltip>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
             
              
              {(user.username === "super" || settings?.usePretreatmentBarcode) && (
                <div className="card p-10 mb-10 pretreatment-container">
                  <div className="input-checkbox-container">
                    <input
                      type="checkbox"
                      defaultChecked={
                        configures?.usePretreatmentBarcode
                          ? configures.usePretreatmentBarcode
                          : false
                      }
                      id="usePretreatmentBarcode"
                      onChange={(e) =>
                        onChangeHandler({
                          id: e.target.id,
                          value: e.currentTarget.checked,
                        })
                      }
                    />
                    <label htmlFor="usePretreatmentBarcode">
                      Print a pretreatment barcode {user.username === "super" && <small>; super</small>}
                    </label>
                  </div>
                  <div className="input-field">
                    <label htmlFor="pretreatmentConfigures.model">
                      Pretreatment Model
                    </label>
                    <select
                      id="pretreatmentConfigures.model"
                      defaultValue={
                        configures?.pretreatmentConfigures?.model
                          ? configures.pretreatmentConfigures.model
                          : ""
                      }
                      onChange={(e) =>
                        onChangeHandler({
                          id: e.target.id,
                          value: e.target.value,
                        })
                      }
                    >
                      <option value="">== Select a model ==</option>
                      {pretreatmentModels.map((model, index) => {
                        return (
                          <option
                            key={model?.value ? model.value : index}
                            value={model?.value ? model.value : ""}
                          >
                            {model.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="input-field">
                    <label htmlFor="pretreatmentConfigures.defaultValue">
                      Default Value
                    </label>
                    <input
                      type="text"
                      id="pretreatmentConfigures.defaultValue"
                      defaultValue={
                        configures?.pretreatmentConfigures?.defaultValue
                          ? configures.pretreatmentConfigures.defaultValue
                          : ""
                      }
                      onChange={(e) =>
                        onChangeHandler({
                          id: e.target.id,
                          value: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="card carriage-speed-container">
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <span className="block font-weight-bold">
                        Carriage Speed base on Color
                      </span>
                      <Tooltip
                        align="right"
                        justify="start"
                        darkMode={true}
                        className="lg-tooltip-container"
                        trigger={
                          <IconButton
                            onClick={() => speedColorHandler({ flag: true })}
                            aria-label="Add a carriage speed"
                          >
                            <FaPlusSquare size="1.5em" color="#09804C" />
                          </IconButton>
                        }
                      >
                        {" "}
                        Add a carriage speed
                      </Tooltip>
                    </div>
                    {configures?.pretreatmentConfigures?.speedColor.map(
                      (item, index) => {
                        return (
                          <div
                            key={item?._id ? item?._id : index}
                            className="carriage-speed"
                          >
                            <div className="input-field">
                              <label htmlFor={`pretreatment_speed_${index}`}>
                                Speed
                              </label>
                              <input
                                type="text"
                                id={`pretreatment_speed_${index}`}
                                defaultValue={item?.speed ? item.speed : ""}
                                onChange={(e) =>
                                  onChangeHandler({
                                    id: e.target.id,
                                    value: e.target.value,
                                  })
                                }
                                required={configures?.usePretreatmentBarcode}
                              />
                            </div>
                            <div className="input-field">
                              <label htmlFor={`pretreatment_color_${index}`}>
                                Color
                              </label>
                              <input
                                type="text"
                                id={`pretreatment_color_${index}`}
                                defaultValue={item?.color ? item.color : ""}
                                onChange={(e) =>
                                  onChangeHandler({
                                    id: e.target.id,
                                    value: e.target.value,
                                  })
                                }
                                required={configures?.usePretreatmentBarcode}
                                style={{ textTransform: "uppercase" }}
                              />
                            </div>
                            <div className="d-flex justify-content-end align-items-center">
                              <Tooltip
                                align="right"
                                justify="start"
                                darkMode={true}
                                className="lg-tooltip-container"
                                trigger={
                                  <IconButton
                                    onClick={() =>
                                      speedColorHandler({ flag: false, index })
                                    }
                                    aria-label="Remove a carriage speed"
                                  >
                                    <FaTimes color="#d91b42" />
                                  </IconButton>
                                }
                              >
                                Remove a carriage speed
                              </Tooltip>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              )}
              {(user.username === "super" || _.includes(settings?.company.toLowerCase(), 'aspen')) && (
                <div className="card p-10 use-item-options-container">
                  <div className="input-checkbox-container">
                    <input
                      type="checkbox"
                      defaultChecked={
                        configures?.useItemOptions
                          ? configures.useItemOptions
                          : false
                      }
                      id="useItemOptions"
                      onChange={(e) =>
                        onChangeHandler({
                          id: e.target.id,
                          value: e.currentTarget.checked,
                        })
                      }
                    />
                    <label htmlFor="useItemOptions">Use item options<small>; aspen company</small></label>
                    <br />
                    <small>
                      Displaying a designated key and value of order item option
                      on a sticker
                    </small>
                  </div>
                  <div className="card item-option-keys-container">
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <span className="block font-weight-bold">Key Names</span>
                      <Tooltip
                        align="right"
                        justify="start"
                        darkMode={true}
                        className="lg-tooltip-container"
                        trigger={
                          <IconButton
                            onClick={() => optionKeyHandler({ flag: true })}
                            aria-label="Add a key name"
                          >
                            <FaPlusSquare size="1.5em" color="#09804C" />
                          </IconButton>
                        }
                      >
                        {" "}
                        Add a key name
                      </Tooltip>
                    </div>
                    {configures?.itemOptionKeys?.map((option, index) => {
                      return (
                        <div className="item-option-keys" key={index}>
                          <div className="input-field">
                            <input
                              type="text"
                              id={`keyName_${index}`}
                              defaultValue={option ? option : ""}
                              onChange={(e) =>
                                onChangeHandler({
                                  id: e.target.id,
                                  value: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="d-flex justify-content-end align-items-start">
                            <Tooltip
                              align="right"
                              justify="start"
                              className="lg-tooltip-container"
                              darkMode={true}
                              trigger={
                                <IconButton
                                  onClick={() =>
                                    optionKeyHandler({ flag: false, index })
                                  }
                                  aria-label="Remove a key name"
                                >
                                  <FaTimes color="#d91b42" />
                                </IconButton>
                              }
                            >
                              Remove a key name
                            </Tooltip>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </Tab>
          <Tab name="Features">
            <div className="tab-wrapper">
              <div className="card p-10 mb-10">
                {(user.username === "super" || _.includes(settings?.company.toLowerCase(), '365')) && (
                  <>
                    <div className="input-checkbox-container">
                      <input
                        type="checkbox"
                        id="usePrimarySKU"
                        defaultChecked={
                          configures?.usePrimarySKU
                            ? configures.usePrimarySKU
                            : false
                        }
                        onChange={(e) =>
                          onChangeHandler({
                            id: e.target.id,
                            value: e.currentTarget.checked,
                          })
                        }
                      />
                      <label htmlFor="usePrimarySKU">Use Primary SKU; <small>365</small></label>
                    </div>
                    <div className="input-field">
                      <label htmlFor="primarySKUSeparator">
                        Primary SKU Separator
                      </label>
                      <input
                        type="text"
                        id="primarySKUSeparator"
                        defaultValue={
                          configures?.primarySKUSeparator
                            ? configures.primarySKUSeparator
                            : ""
                        }
                        onChange={(e) =>
                          onChangeHandler({
                            id: e.target.id,
                            value: e.target.value,
                          })
                        }
                      />
                    </div>
                  </>
                )}
                <div className="input-checkbox-container">
                  <input
                    type="checkbox"
                    id="useProductDescription"
                    defaultChecked={
                      configures?.useProductDescription
                        ? configures.useProductDescription
                        : false
                    }
                    onChange={(e) =>
                      onChangeHandler({
                        id: e.target.id,
                        value: e.currentTarget.checked,
                      })
                    }
                  />
                  <label htmlFor="useProductDescription">
                    Use product's description
                  </label>
                  <br />
                  <small>
                    Do not import item's description while syncing orders
                  </small>
                </div>
                { (user.username === "super" || _.includes(settings?.company.toLowerCase(), 'kite')) && (
                  <div className="input-checkbox-container">
                    <input
                      type="checkbox"
                      id="useSearchProductions"
                      defaultChecked={
                        configures?.useSearchProductions
                          ? configures.useSearchProductions
                          : false
                      }
                      onChange={(e) =>
                        onChangeHandler({
                          id: e.target.id,
                          value: e.currentTarget.checked,
                        })
                      }
                    />
                    <label htmlFor="useSearchProductions">
                      Use Search Productions <small>; Kite & Crest</small>
                    </label>
                    <br />
                    <small>Retrive productions only in search results(Productions page)</small>
                  </div>
                )}
                { (user.username === "super" || _.includes(settings?.company.toLowerCase(), 'wall')) && (
                  <div className="input-checkbox-container">
                    <input
                      type="checkbox"
                      id="usePolarisRecipe"
                      defaultChecked={
                        configures?.usePolarisRecipe
                          ? configures.usePolarisRecipe
                          : false
                      }
                      onChange={(e) =>
                        onChangeHandler({
                          id: e.target.id,
                          value: e.currentTarget.checked,
                        })
                      }
                    />
                    <label htmlFor="usePolarisRecipe">
                      Use Polaris Recipe <small>; Wallflower</small>
                    </label>
                    <br />
                    <small>Using Polaris(Caldera) recipe</small>
                  </div>
                )}
              </div>
              { (user.username === "super" || _.includes(settings?.company.toLowerCase(), 'storm')) && (
                <div className="card p-10 use-order-status-container">
                  <div className="input-checkbox-container">
                    <input
                      type="checkbox"
                      defaultChecked={
                        configures?.useWorkOrderStatus
                          ? configures.useWorkOrderStatus
                          : false
                      }
                      id="useWorkOrderStatus"
                      onChange={(e) =>
                        onChangeHandler({
                          id: e.target.id,
                          value: e.currentTarget.checked,
                        })
                      }
                    />
                    <label htmlFor="useWorkOrderStatus">
                      Use work order status; <small>TShirt Storm</small>
                    </label>
                    <br />
                    <small>
                      Displaying a work order status on work orders list
                    </small>
                  </div>
                  {configures?.useWorkOrderStatus && (
                    <div className="card order-status-keys-container">
                      <div
                        className="d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        <span className="block font-weight-bold">
                          Work order statuses
                        </span>
                        <Tooltip
                          align="right"
                          justify="start"
                          darkMode={true}
                          className="lg-tooltip-container"
                          trigger={
                            <IconButton
                              onClick={() =>
                                workOrderStatusesHandler({ flag: true })
                              }
                              aria-label="Add an order status key name"
                            >
                              <FaPlusSquare size="1.5em" color="#09804C" />
                            </IconButton>
                          }
                        >
                          {" "}
                          Add a status key
                        </Tooltip>
                      </div>
                      {configures?.workOrderStatuses?.map((status, index) => {
                        return (
                          <div className="order-status-keys" key={index}>
                            <div className="input-field">
                              <label htmlFor={`workorderstatus_name_${index}`}>
                                Name
                              </label>
                              <input
                                type="text"
                                id={`workorderstatus_name_${index}`}
                                defaultValue={status?.name ? status.name : ""}
                                onChange={(e) =>
                                  onChangeHandler({
                                    id: e.target.id,
                                    value: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="input-field">
                              <label htmlFor={`workorderstatus_color_${index}`}>
                                Color
                              </label>
                              <input
                                type="text"
                                id={`workorderstatus_color_${index}`}
                                defaultValue={status?.color ? status.color : ""}
                                onChange={(e) =>
                                  onChangeHandler({
                                    id: e.target.id,
                                    value: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="d-flex justify-content-end align-items-center">
                              <Tooltip
                                align="right"
                                justify="start"
                                className="lg-tooltip-container"
                                darkMode={true}
                                trigger={
                                  <IconButton
                                    onClick={() =>
                                      workOrderStatusesHandler({
                                        flag: false,
                                        index,
                                      })
                                    }
                                    aria-label="Remove a status"
                                  >
                                    <FaTimes color="#d91b42" />
                                  </IconButton>
                                }
                              >
                                Remove a status
                              </Tooltip>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
          </Tab>
          {(configures.defaultIntegratedAutomation === "CADLINK" || cadlinkIntegratedCompanies.indexOf(configures?.company?.toLowerCase()) !== -1 ) && (
            <Tab name="CADlink">
              <div className="tab-wrapper">
                <div className="card mb-10">
                  <div className="queues-container">
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <span className="block font-weight-bold">Multiple Queues</span>
                      <Tooltip
                        align="left"
                        justify="start"
                        darkMode={true}
                        className="lg-tooltip-container"
                        trigger={
                          <IconButton
                            onClick={() => cadLinkQueuesHandler({ flag: true })}
                            aria-label="Add Queue"
                          >
                            <FaPlusSquare size="1.5em" color="#09804C" />
                          </IconButton>
                        }
                      >
                        Add Queue
                      </Tooltip>
                    </div>
                    {configures?.cadLinkQueues?.map((q, index) => {
                      return (
                        <div key={index} className="queue-wrapper">
                          <div className="input-field">
                            <label htmlFor={`cadLinkQueues_name_${index}`}>Name</label>
                            <input
                              type="text"
                              id={`cadLinkQueues_name_${index}`}
                              defaultValue={q?.name ? q.name : ""}
                              onChange={(e) =>
                                onChangeHandler({
                                  id: e.target.id,
                                  value: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="input-field">
                            <label htmlFor={`cadLinkQueues_hotFolder_${index}`}>Hot folder</label>
                            <input
                              type="text"
                              id={`cadLinkQueues_hotFolder_${index}`}
                              defaultValue={q?.hotFolder ? q.hotFolder : ""}
                              onChange={(e) =>
                                onChangeHandler({
                                  id: e.target.id,
                                  value: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="input-field">
                            <label htmlFor={`cadLinkQueues_printMode_${index}`}>Print mode(Default)</label>
                            <input
                              type="text"
                              id={`cadLinkQueues_printMode_${index}`}
                              defaultValue={q?.printMode ? q.printMode : ""}
                              onChange={(e) =>
                                onChangeHandler({
                                  id: e.target.id,
                                  value: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="input-field">
                            <label htmlFor={`cadLinkQueues_hostnames_${index}`}>Host names</label>
                            <input
                              type="text"
                              id={`cadLinkQueues_hostnames_${index}`}
                              defaultValue={q?.hostnames ? q.hostnames : ""}
                              onChange={(e) =>
                                onChangeHandler({
                                  id: e.target.id,
                                  value: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="d-flex justify-content-end align-items-center">
                            <Tooltip
                              align="bottom"
                              justify="middle"
                              className="lg-tooltip-container"
                              darkMode={true}
                              trigger={
                                <IconButton
                                  onClick={() =>
                                    cadLinkQueuesHandler({ flag: false, index })
                                  }
                                  aria-label="Remove queue"
                                >
                                  <FaTimes color="#d91b42" />
                                </IconButton>
                              }
                            >
                              Remove media
                            </Tooltip>
                          </div>
                        </div>
                      )})
                    }
                    
                  </div>
                </div>    
                <div className="card mb-10">
                  <div className="print-modes-container">
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <span className="block font-weight-bold">Print Modes</span>
                      <Tooltip
                        align="left"
                        justify="start"
                        darkMode={true}
                        className="lg-tooltip-container"
                        trigger={
                          <IconButton
                            onClick={() => cadLinkPrintModesHandler({ flag: true })}
                            aria-label="Add Print Mode"
                          >
                            <FaPlusSquare size="1.5em" color="#09804C" />
                          </IconButton>
                        }
                      >
                        Add Queue
                      </Tooltip>
                    </div>
                    {configures?.cadLinkPrintModes?.map((p, index) => {
                      return (
                        <div key={index} className="print-modes-wrapper">
                          <div className="input-field">
                            <label htmlFor={`cadLinkPrintModes_name_${index}`}>Name</label>
                            <input
                              type="text"
                              id={`cadLinkPrintModes_name_${index}`}
                              defaultValue={p?.name ? p.name : ""}
                              onChange={(e) =>
                                onChangeHandler({
                                  id: e.target.id,
                                  value: e.target.value,
                                })
                              }
                            />
                          </div>
   
                          <div className="d-flex justify-content-end align-items-center">
                            <Tooltip
                              align="bottom"
                              justify="middle"
                              className="lg-tooltip-container"
                              darkMode={true}
                              trigger={
                                <IconButton
                                  onClick={() =>
                                    cadLinkPrintModesHandler({ flag: false, index })
                                  }
                                  aria-label="Remove print mode"
                                >
                                  <FaTimes color="#d91b42" />
                                </IconButton>
                              }
                            >
                              Remove media
                            </Tooltip>
                          </div>
                        </div>
                      )})
                    }
                  </div>
                </div>
                <div className="card mb-10">
                  <div className="media-container">
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}
                    >
                      <span className="block font-weight-bold">Media</span>
                      <Tooltip
                        align="left"
                        justify="start"
                        darkMode={true}
                        className="lg-tooltip-container"
                        trigger={
                          <IconButton
                            onClick={() => mediaHandler({ flag: true })}
                            aria-label="Add media"
                          >
                            <FaPlusSquare size="1.5em" color="#09804C" />
                          </IconButton>
                        }
                      >
                        Add media
                      </Tooltip>
                    </div>
                    {configures?.media?.map((m, index) => {
                      return (
                        <div key={index} className="media-wrapper">
                          <div className="input-field">
                            <label htmlFor={`media_code_${index}`}>Code</label>
                            <input
                              type="text"
                              id={`media_code_${index}`}
                              defaultValue={m?.code ? m.code : ""}
                              onChange={(e) =>
                                onChangeHandler({
                                  id: e.target.id,
                                  value: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="input-field">
                            <label htmlFor={`media_name_${index}`}>Name</label>
                            <input
                              type="text"
                              id={`media_name_${index}`}
                              defaultValue={m?.name ? m.name : ""}
                              onChange={(e) =>
                                onChangeHandler({
                                  id: e.target.id,
                                  value: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="input-field">
                            <label htmlFor={`media_size_width_${index}`}>
                              Width(inch)
                            </label>
                            <input
                              type="text"
                              id={`media_size_width_${index}`}
                              defaultValue={m?.size.width ? m.size.width : ""}
                              onChange={(e) =>
                                onChangeHandler({
                                  id: e.target.id,
                                  value: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="input-field">
                            <label htmlFor={`media_size_height_${index}`}>
                              Height(inch)
                            </label>
                            <input
                              type="text"
                              id={`media_size_height_${index}`}
                              defaultValue={m?.size.height ? m.size.height : ""}
                              onChange={(e) =>
                                onChangeHandler({
                                  id: e.target.id,
                                  value: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="input-field">
                            <label htmlFor={`media_type_${index}`}>Type</label>
                            <select
                              defaultValue={m?.type ? m?.type : ""}
                              onChange={(e) =>
                                onChangeHandler({
                                  id: e.target.id,
                                  value: e.target.value,
                                })
                              }
                              id={`media_type_${index}`}
                            >
                              {/* <option value="">== Select an option ==</option> */}
                              {mediaTypes.map((t, index) => {
                                return (
                                  <option value={t?.value} key={index}>
                                    {t.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="input-field">
                            <label htmlFor={`media_isDefault_${index}`}>Default</label>
                            <input
                              type="checkbox"
                              defaultChecked={m?.isDefault ? m.isDefault : false}
                              id={`media_isDefault_${index}`}
                              onChange={(e) =>
                                onChangeHandler({
                                  id: e.target.id,
                                  value: e.currentTarget.checked,
                                })
                              }
                              style={{marginTop: 7}}
                            />
                          </div>

                          <div className="d-flex justify-content-end align-items-center">
                            <Tooltip
                              align="bottom"
                              justify="middle"
                              className="lg-tooltip-container"
                              darkMode={true}
                              trigger={
                                <IconButton
                                  onClick={() =>
                                    mediaHandler({ flag: false, index })
                                  }
                                  aria-label="Remove media"
                                >
                                  <FaTimes color="#d91b42" />
                                </IconButton>
                              }
                            >
                              Remove media
                            </Tooltip>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="card p-10">
                  <div className="input-checkbox-container">
                    <input
                      type="checkbox"
                      defaultChecked={
                        configures?.usePrintMode
                          ? configures.usePrintMode
                          : false
                      }
                      id="usePrintMode"
                      onChange={(e) =>
                        onChangeHandler({
                          id: e.target.id,
                          value: e.currentTarget.checked,
                        })
                      }
                    />
                    <label htmlFor="usePrintMode">
                      Use print mode
                    </label>
                    <br />
                    <small>
                      Use print mode instead of rip env.
                    </small>
                  </div>
                </div>
              </div>
            </Tab>
          )}
        </Tabs>

        {/* <pre>{ JSON.stringify(configures, null, 2)}</pre> */}
      </section>
    </>
  );
}
