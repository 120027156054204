import React, {useContext, useState, useEffect} from 'react'
import { SettingsContext } from "contexts/SettingsContext";
import { AuthContext } from "contexts/AuthContext"
import PageMainHeader from 'components/PageMainHeader'
import { Title } from 'utils/Title';
import { useQuery } from "react-query";
import { startOfYear, endOfYear, endOfMonth, sub, startOfDay, endOfDay, format } from "date-fns";
import { errorHandler } from "../utils/errorHandler";
import { Bar } from "react-chartjs-2";
import _ from "lodash";
import { axiosInstance as axios } from '../configs/axiosConfig';
import Banner from '@leafygreen-ui/banner';
import ScrollTop from 'utils/ScrollTop';
import { sessionValidate } from 'utils/sessionValidate';

const title="Dashboard";

const fetchDaily = ({startDate, endDate}) => {
  return new Promise((resolve, reject) => {
    axios.get('/dashboard/fetch-daily', { params: { startDate, endDate } })
      .then(response => {
        // console.log("fetchDaily response: ", response);
        resolve(response.data)
      })
      .catch(error => {
        console.log("fetchDaily error: ", errorHandler(error));
        const retval = errorHandler(error);
        reject(retval)
      })
  })
}

const fetchMonthly = ({ startDate, endDate }) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/dashboard/fetch-monthly", { params: { startDate, endDate } })
      .then((response) => {
        // console.log("fetchMonthly response: ", response);
        resolve(response.data);
      })
      .catch((error) => {
        console.log("fetchMonthly error: ", errorHandler(error));
        const retval = errorHandler(error);
        reject(retval);
      });
  });
};

const fetchYearly = ({ startDate, endDate }) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/dashboard/fetch-yearly", {
        params: { startDate, endDate },
      })
      .then((response) => {
        // console.log("fetchYearly response: ", response);
        resolve(response.data);
      })
      .catch((error) => {
        console.log("fetchYearly error: ", errorHandler(error));
        const retval = errorHandler(error);
        reject(retval);
      });
  });
};

const fetchDay = ({ startDate, endDate }) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/dashboard/fetch-day", { params: { startDate, endDate } })
      .then((response) => {
        // console.log("fetchDayPrinted response: ", response);
        resolve(response.data);
      })
      .catch((error) => {
        console.log("fetchDayPrinted error: ", errorHandler(error));
        const retval = errorHandler(error);
        reject(retval);
      });
  });
};

function fetchDayByUser({ startDate, endDate }) {
  return new Promise(async (resolve, reject) => {
    console.log("* fetchDayByUser init");
     try {
       const results = await fetchDay({
         startDate,
         endDate,
       });
      // console.log("- fetchDayByUser results: ", results);
       resolve(results);
     } catch (error) {
       console.log("fetchDayByUser error: ", errorHandler(error));
       const retval = errorHandler(error);
       reject(retval);
     }
  })
 }

const backgroundColors = {
  ordered: "#ef5350",
  printed: "#03a9f4",
  shipped: "#7e57c2",
};


export default function Dashboard() {
  const { settings, sessions, hostname } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);

  const [dailyQuantity, setDailyQuantity] = useState(null);
  const [monthlyQuantity, setMonthlyQuantity] = useState(null);
  const [yearlyQuantity, setYearlyQuantity] = useState(null);
  const [dayRefs, setDayRefs] = useState(null)
  const [msg, setMsg] = useState(null);
  const [sessionMsg, setSessionMsg] = useState(null)

  let refetchInterval = 1000*60*5 // 5mins

  const {data: today} = useQuery(['day'], () => {
    let startDate = startOfDay(new Date())
    let endDate = endOfDay(new Date())
    return fetchDayByUser({startDate, endDate})
  },
  {
    refetchOnWindowFocus: false,
    refetchInterval,
  })
  // today && console.log("today: ", today);

  const {
    data: daily,
    isError,
    error,
  } = useQuery(
    ["daily"],
    () => {
      let startDate = startOfDay(sub(new Date(), {months: 1}));
      let endDate = endOfMonth(new Date());
      return fetchDaily({ startDate, endDate });
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval,
    }
  );

  // daily && console.log("daily: ", daily);

  const { data: monthly } = useQuery(
    ["monthly"],
    () => {
      let startDate = startOfYear(new Date());
      let endDate = endOfMonth(new Date());
      return fetchMonthly({ startDate, endDate });
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval,
    }
  );

  // monthly && console.log("monthly: ", monthly);

  const { data: yearly } = useQuery(
    ["yearly"],
    () => {
      let startDate = startOfYear(sub(new Date(), {years: 2}));
      let endDate = endOfYear(new Date());
      return fetchYearly({ startDate, endDate });
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval,
    }
  );
  // yearly && console.log("yearly: ", yearly);

  function settingDayData(results) {
    console.log("* settingDayData init");
    // console.log("- results: ", results);

    // set printed data;
    if (Boolean(results?.printed.length)) {
      let printedLabels = [
        `${Object.values(results["printed"])[0]["_id"]["date"]} :: ${_.sumBy(
          results["printed"],
          "quantity"
        )} pcs`,
      ];
      // console.log("- printedLabels: ", printedLabels);

      let printedDatasets = Object.values(results["printed"]).map((p) => {
        return {
          label: p._id.username,
          data: [p.quantity],
          backgroundColor: "#" + Math.random().toString(16).substr(2, 6),
        };
      });

      // console.log("- printedDatasets: ", printedDatasets);


      setDayRefs((current) => {
        return {
          ...current,
          printed: { labels: printedLabels, datasets: printedDatasets },
        };
      });
     
    }
    if (Boolean(results?.shipped.length)) {
      let shippedLabels = [
        `${Object.values(results["shipped"])[0]["_id"]["date"]} :: ${_.sumBy(
          results["shipped"],
          "quantity"
        )} pcs`,
      ];
      // console.log("- shippedLabels: ", shippedLabels);

      let shippedDatasets = Object.values(results["shipped"]).map((p) => {
        return {
          label: p._id.username,
          data: [p.quantity],
          backgroundColor: "#" + Math.random().toString(16).substr(2, 6),
        };
      });
      // console.log("- shippedDatasets: ", shippedDatasets);

      setDayRefs((current) => {
        return {...current, shipped: { labels: shippedLabels, datasets: shippedDatasets }}
      });
    }
  }

  function getElementAtEventHandler(element) {
    console.log("* getElementAtEventHandler init");
    // console.log("- element: ", element);
    // console.log('- dailyQuantity: ', dailyQuantity)
    if (Boolean(element.length)) {
      setDayRefs(null);
      const { index } = element[0];
      // console.log("- index: ", index);
      let dateStr = dailyQuantity?.labels[index] ?? dailyQuantity?.labels[index]
      // console.log('- dateStr: ', dateStr)

      const dailyObject = daily["daily"]
      // console.log('- dailyObject: ', dailyObject)

      let printedObj = _.find(dailyObject.printed, {_id: dateStr})
      // console.log('- printedObj: ', printedObj)
      let shippedObj = _.find(dailyObject.shipped, {_id: dateStr})
      // console.log('- shippedObj: ', shippedObj)
      
      if(dateStr === format(new Date(), 'MM-dd-yyyy')) {
        settingDayData(today);
      } else {
        // console.log('- selected not today')
        let printed = [], shipped = [];

        if(printedObj) {
          printed = printedObj?.users && 
            printedObj.users.map(user => {
              const { username, quantity } = user
              return {_id: {username, date: dateStr}, quantity}
            }) 
        }
        // console.log("- printed: ", printed);
        
        if(shippedObj) {
          shipped = shippedObj?.users && shippedObj.users.map(user => {
            const { username, quantity } = user
            // console.log(username, quantity, dateStr)
            return {_id: {username, date: dateStr}, quantity}
          }) 
        }
        // console.log("- shipped: ", shipped);

        settingDayData({printed, shipped});
      }
    }
  }

  function getTodayObjects(id,  payload) {
    console.log('* getTodayObjects init')
    // console.log('- id(todayId): ', id)
    // console.log('- payload(today data): ', payload)
    let obj = {
      ordered: { _id: id, quantity: today?.ordered ? _.sumBy(today?.ordered, 'quantity'): 0},
      printed: { _id: id, quantity: _.sumBy(today?.printed, 'quantity')},
      shipped: { _id: id, quantity: _.sumBy(today?.shipped, 'quantity')},
    }
    return obj
  }

  useEffect(() => {
    if (daily) {
      console.log("* daily hook init");
      let dailyObject = daily?.daily;
      // console.log("- dailyObject: ", dailyObject);
      // today && console.log('- today: ', today)
      let todayObject, todayId;
      if(today) {
        // todayId = today?.printed[0]?._id?.date
        todayId = format(new Date(), 'MM-dd-yyyy')
        // console.log('- todayId: ', todayId)
        todayObject = getTodayObjects(todayId, today)
      }
      if (dailyObject) {
        // console.log('- todayObejct: ', todayObject)
        if(todayObject) {
          _.isNil(_.find(dailyObject.ordered, {_id: todayId})) && dailyObject.ordered.unshift(todayObject.ordered)
          _.isNil(_.find(dailyObject.printed, {_id: todayId})) && dailyObject.printed.unshift(todayObject.printed)
          _.isNil(_.find(dailyObject.shipped, {_id: todayId})) && dailyObject.shipped.unshift(todayObject.shipped)
        }
        let labels = [];
        for (let key of Object.keys(dailyObject)) {
          // console.log("- key: ", key);
          // console.log(dailyObject[key]);
          for (let d of dailyObject[key]) {
            // console.log("d.id", d._id)
            // console.log(_.indexOf(labels, d._id));
            if (labels.indexOf(d._id) === -1) {
              labels.push(d._id);
            }
          }
        }

        labels = labels.sort((a, b) => {
          const aArr = a.split("-");
          const bArr = b.split("-");
          const aStr = `${aArr[2]}${aArr[0]}${aArr[1]}`
          const bStr = `${bArr[2]}${bArr[0]}${bArr[1]}`
          return aStr>bStr ? -1 : aStr<bStr ? 1 : 0
        });
        // labels = _.sortBy(labels, ["desc"]);
        // console.log("- labels: ", labels);

        let datasets = Object.entries(dailyObject).map((d) => {
          // console.log("- d: ", d)
          return {
            label: d[0],
            data: labels.map((l, index) => {
              // console.log("- l: ", index, l);
              let found = _.find(d[1], { _id: l });
              // console.log("- found: ", found)
              return found ? found.quantity : 0
            }),
            backgroundColor: backgroundColors[d[0]],
          };
        });
        // console.log("- datasets: ", datasets);
        setDailyQuantity({ labels, datasets });
      }

      //setSelectedDate
      // console.log("Object.values(Object.entries(daily['daily'])[0])[1])", Object.values(Object.entries(daily["daily"])[0])[1]);
      // console.log("** :", Boolean(Object.values(Object.entries(dailyObject)[0])[1].length));
      if (Boolean(Object.values(Object.entries(dailyObject)[0])[1].length)) {
        let selectedDate = Object.values(Object.entries(dailyObject)[1])[1][0]?._id;
        // console.log("- selectedDate: ", selectedDate, new Date(selectedDate));
        if (selectedDate) {
          if(todayId && selectedDate !== todayId) {
            let startDate = startOfDay(new Date(selectedDate));
            let endDate = endOfDay(new Date(selectedDate));
            // console.log(` - startDate: ${startDate}`);
            // console.log(` - endDate: ${endDate}`);
            fetchDayByUser({ startDate, endDate })
              .then(results => {
                settingDayData(results);
              })
              .catch(error => {
                setMsg(error)
              })
          } else {
            settingDayData(today);
          }
        }
      }
    }
  // eslint-disable-next-line
  }, [daily, today])
  
  useEffect(() => {
    if (monthly) {
      console.log("* monthly hook init");
      let monthlyObjects = monthly?.monthly
      let todayObject, todayId;
      let keyIndex = 0;
      if(today) {
        todayId = format(new Date(), 'MM-dd-yyyy')
        // console.log('- todayId: ', todayId)
        let monthYearStr = `${todayId.substring(0, 2)}${todayId.substring(5)}`
        // console.log('- monthYearStr: ', monthYearStr)
        todayObject = getTodayObjects(todayId, today)
        // console.log('- todayObject: ', todayObject)
        let i = 0;
        for (const key in monthlyObjects) {
          if (Object.hasOwnProperty.call(monthlyObjects, key)) {
            // console.log(' key: ', key)
            const obj = monthlyObjects[key];
            // console.log('- obj: ', obj, typeof obj)
            if(Boolean(obj.length)) keyIndex = i
            i = i + 1
            for (const o of obj) {
              // console.log('- o: ', o)
              if(o._id === monthYearStr){
                o.quantity += todayObject[key].quantity
              }
            }
            
          }
        }
        
      }
      // console.log('- keyIndex: ', keyIndex)
      // console.log('- settings?.company: ', settings?.company)
      if(settings?.company.toLowerCase().includes('shirtbanc')) keyIndex = 0
      let labels = Object.values(Object.entries(monthlyObjects)[keyIndex])[1].map(e => (e._id));
      let datasets = Object.entries(monthlyObjects).map(d => {
        return {
          label: d[0],
          data: Object.values(d[1]).map(e => (e.quantity)),
          backgroundColor: backgroundColors[d[0]] 
        }
      });
      // console.log("- labels: ", labels)
      // console.log("- datasets: ", datasets)
      setMonthlyQuantity({ labels, datasets });
    }
  // eslint-disable-next-line
  }, [monthly, today])

  useEffect(() => {
    if (yearly) {
      console.log("* yearly hook init");
      let yearlyObjects = yearly?.yearly
      let todayObject;
      let todayId = format(new Date(), 'MM-dd-yyyy')
      let keyIndex = 0;
      let yearStr = `${todayId.substring(6)}`

      if(today) {
        // console.log('- todayId: ', todayId)
        console.log('- yearStr: ', yearStr)
        todayObject = getTodayObjects(todayId, today)
        // console.log('- todayObject: ', todayObject)
        let i = 0;
        for (const key in yearlyObjects) {
          if (Object.hasOwnProperty.call(yearlyObjects, key)) {
            // console.log(' key: ', key)
            const obj = yearlyObjects[key];
            if(obj) keyIndex = i;
            i = i + 1;
            for (const o of obj) {
              // console.log('- o: ', o)
              if(o._id === yearStr){
                o.quantity += todayObject[key].quantity
              }
            }
            
          }
        }
        
      }
      let labels = Object.values(Object.entries(yearlyObjects)[keyIndex])[1].map(
        (e) => e._id
      );
      let datasets = Object.entries(yearlyObjects).map((d) => {
        // console.log('- d: ', d)
        return {
          label: d[0],
          data: labels.map((l, index) => {
              // console.log("- l: ", index, l);
              let found = _.find(d[1], { _id: l });
              // console.log("- found: ", found)
              return found ? found.quantity : 0
            }),
          // data: Object.values(d[1]).map((e) => e.quantity),
          backgroundColor: backgroundColors[d[0]],
        };
      });
      // console.log("- labels: ", labels)
      // console.log("- datasets: ", datasets)
      setYearlyQuantity({ labels, datasets });
    }
    // eslint-disable-next-line
  }, [yearly, today]);

  useEffect(() => {
    console.log('* session validation hook ran');
    // console.log('- settings: ', settings);
    if(!_.isNil(sessionMsg)) setSessionMsg(null);
    if(!settings?.useUnlockedPrintflo){
      console.log('[Session Validation Hook] Subscription customer')
      const sessionValidateResult = sessionValidate({sessions, hostname});
      console.log("- sessionValidateResult: ", sessionValidateResult)
      if(sessionValidateResult?.message) {
        setSessionMsg(sessionValidateResult.message);
      }
    } else {
      console.log('[Session Validation Hook] Unlocked version')
    }

    return () => setSessionMsg(null);
  // eslint-disable-next-line
  }, [sessions, hostname, settings])

  return (
    <>
      <Title title={title} /> 
      <PageMainHeader 
        title={title} 
        user={user} 
        settings={settings} 
      />
      <section className="primary">
        {/* <Banner className='mb-10'>Update Notification</Banner> */}
        {isError && (
          <Banner variant="danger" className='mb-10'>{error}</Banner>
        )}
        {msg && (
          <Banner className='mb-10'>{msg}</Banner>
        )}
        {sessionMsg && (
          <Banner className='mb-10' variant="warning">{sessionMsg}</Banner>
        )}

        {dailyQuantity && (
          <div className="card p-10">
            <div className="d-flex" style={{ justifyContent: "center" }}>
              <h3>Work Order References</h3>
            </div>
            <div>
              <Bar
                data={dailyQuantity}
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text: "Daily",
                    },
                    legend: {
                      display: true,
                    },
                  },
                }}
                height={70}
                getElementAtEvent={async (element) => {
                  getElementAtEventHandler(element);
                }}
              />
            </div>
              {/* <pre>{JSON.stringify(dailyQuantity, null, 2)}</pre> */}
          </div>
        )}

        <div className={`card ${!dayRefs?.printed && !dayRefs?.shipped ? "hidden" : ""} p-10 mt-10 day-refs`}>
          {dayRefs?.printed && (
            <div className="card p-10">
              <Bar
                data={dayRefs.printed}
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text: "Printed by users",
                    },
                    legend: {
                      display: true,
                    },
                  },
                }}
                height={100}
              />
            </div>
          )}
          {dayRefs?.shipped && (
            <div className="card p-10">
              <Bar
                data={dayRefs.shipped}
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text: "Shipped by users",
                    },
                    legend: {
                      display: true,
                    },
                  },
                }}
                height={100}
              />
            </div>
          )}
        {/* <pre>dayRefs: { JSON.stringify(dayRefs, null, 2)}</pre> */}
        </div>

         {monthlyQuantity && (
            <div className="card p-10 mt-10">
              <Bar
                data={monthlyQuantity}
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text: "Monthly",
                    },
                    legend: {
                      display: true,
                    },
                  },
                }}
                height={70}
              />
            </div>
          )}
          {yearlyQuantity && (
            <div className="card p-10 mt-10">
              <Bar
                data={yearlyQuantity}
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text: "Yearly",
                    },
                    legend: {
                      display: true,
                    },
                  },
                }}
                height={70}
              />
            </div>
          )}       
      <ScrollTop />
      </section>
    </>
  )
}
