import { axiosInstance as axios } from "configs/axiosConfig";

export const getPackingItems = (data) => {
  console.log("* getPackingItems(util) init");
  return new Promise(async (resolve, reject) => {
    try {
      const url = `/packing-items`;
      const response = await axios.get(url, { params: data });
      // console.log("[getPackingItems] response", response);
      if (data?.page) {
        resolve(response.data);
        return;
      } else {
        resolve(response.data.docs);
      }
    } catch (error) {
      console.error("getPackingItems error", error);
      reject(error);
    }
  });
};

export const getPackingItemsByOrder = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `/packing-items/get-by-order`;
      const response = await axios.get(url, { params: payload });
      // console.log("getPackingItemsByOrder response", response);
      resolve(response.data);
    } catch (error) {
      console.error("getPackingItemsByOrder error", error);
      reject(error);
    }
  });
};

export const getPackingItem = (data) => {
  return new Promise((resolve, reject) => {
    const url = `/packing-items/get-by-sku`;

    axios
      .post(url, data)
      .then((res) => {
        // console.log("getPackingItem res", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("getPackingItem err", err);
        reject(err);
      });
  });
};

export const createPackingItem = (item) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `/packing-items/create`;
      const response = await axios.post(url, item);
      // console.log("create packing items response", response.data);
      resolve(response.data);
    } catch (error) {
      console.log("create packing items error", error);
      reject(error);
    }
  });
};

export const updatePackingItem = (data) => {
  return new Promise((resolve, reject) => {
    const url = `/packing-items/update`;
    axios
      .post(url, data)
      .then((res) => {
        // console.log("updatePackingItem res", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.error("- updatePackingItem err", err);
        reject(err);
      });
    // console.log("* updatePackingItem test mode");
    // setTimeout(() => {
    //   resolve(true)
    // }, 100);
  });
};

export const updatePackingItemPrinted = (data) => {
  return new Promise((resolve, reject) => {
    const url = `/packing-items/update-printed`;

    axios
      .post(url, data)
      .then((res) => {
        // console.log("updatePackingItem res", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("updatePackingItem err", err);
        reject(err);
      });
  });
};

export const updateManyPackingItem = (data) => {
  return new Promise((resolve, reject) => {
    const url = `/packing-items/update-many`;

    axios
      .post(url, data)
      .then((res) => {
        // console.log("updatePackingItem res", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("updatePackingItem err", err);
        reject(err);
      });
  });
};

export const addPackingItem = (data) => {
  return new Promise((resolve, reject) => {
    const url = `/packing-items/add`;

    axios
      .post(url, data)
      .then((res) => {
        // console.log("addPackingItem res", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("updatePackingItem err", err.response.data);
        reject(err);
      });
  });
};

export const deletePackingItem = (orderId) => {
  return new Promise((resolve, reject) => {
    const url = `/packing-items/delete/${orderId}`;

    axios
      .delete(url)
      .then((res) => {
        // console.log("deletePackingItem res", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("deletePackingItem err", err);
        reject(err);
      });
  });
};

export const deletePackingItemById = (id) => {
  return new Promise((resolve, reject) => {
    const url = `/packing-items/delete-by-id/${id}`;

    axios
      .delete(url)
      .then((res) => {
        // console.log("deletePackingItemById res", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("deletePackingItemById err", err);
        reject(err);
      });
  });
};

export const inactivatePackingItemById = (id) => {
  return new Promise((resolve, reject) => {
    const url = `/packing-items/inactivate-by-id/${id}`;

    axios
      .get(url)
      .then((res) => {
        console.log("deletePackingItemById res", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("deletePackingItemById err", err);
        reject(err);
      });
  });
};

export const consolidate = (page, limit) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/packing-items/consolidate", { params: { page, limit } })
      .then((res) => {
        // console.log("- consolidate response", res);
        if (page) resolve(res.data);
        else resolve(res.data.docs);
      })
      .catch((err) => {
        console.log("- consolidate err", err);
        reject(err);
      });
  });
};

export const updatePackingItemsPickedAsOrdered = () => {
  return new Promise((resolve, reject) => {
    const url = `/packing-items/update-picked-ordered`;
    axios
      .post(url)
      .then((res) => {
        console.log("updatePackingItemsPickedAsOrdered res", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("updatePackingItemsPickedAsOrdered err", err);
        reject(err);
      });
  });
};

export const updatePackingItemWorkStatus = (data) => {
  return new Promise((resolve, reject) => {
    const url = `/packing-items/update-work-status`;

    axios
      .post(url, data)
      .then((res) => {
        // console.log("updatePackingItemWorkStatus res", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("updatePackingItemWorkStatus err", err);
        reject(err);
      });
  });
};

export const fetchActivityItems = ({
  page,
  limit = 50,
  startDate,
  endDate,
  searchTxt,
}) => {
  return new Promise((resolve, reject) => {
    // console.log("- page: ", page);
    // console.log("- startDate: ", startDate);
    // console.log("- endDate: ", endDate);
    axios
      .get("/packing-items/activity-items", {
        params: { page, limit, startDate, endDate, searchTxt },
      })
      .then((res) => {
        // console.log("- activity-items response", res);
        if (page) resolve(res.data);
        else resolve(res.data.docs);
      })
      .catch((err) => {
        console.log("- activity err", err);
        reject(err);
      });
  });
};

export const fetchActivityGraphics = ({
  page,
  limit = 50,
  startDate,
  endDate,
  searchTxt,
}) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/packing-items/activity-graphics", {
        params: { page, limit, startDate, endDate, searchTxt },
      })
      .then((res) => {
        // console.log("- activity-graphics response", res);
        if (page) resolve(res.data);
        else resolve(res.data.docs);
      })
      .catch((err) => {
        console.log("- activity err", err);
        reject(err);
      });
  });
};

export const searchPackingItems = ({ key, searchTxt, isActive }) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/packing-items/search", {
        params: { key, searchTxt, isActive },
      })
      .then((res) => {
        // console.log("- activity-graphics response", res);
        resolve(res.data);
      })
      .catch((err) => {
        console.log("- searchPackingItems err", err);
        reject(err);
      });
  });
};
