import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import socketIOClient from "socket.io-client";
import { ToastContainer, toast } from "react-toastify";
import { Title } from "utils/Title";
import PageMainHeader from "components/PageMainHeader";
import { AuthContext } from "contexts/AuthContext";
import { SettingsContext } from "contexts/SettingsContext";
import Banner from "@leafygreen-ui/banner";
import { getPackingItemsByOrder, updatePackingItemWorkStatus } from "utils/packingItems";
import "react-toastify/dist/ReactToastify.css";
import { getS3presign } from "utils/awsS3";
import { ModalPreloader } from "utils/Preloader";
// import queryString from "query-string";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { getShipStationOrder } from "utils/shipstation";
import { getItemDoc } from "utils/items";
import _ from "lodash";
import { Table, TableHeader, Row, Cell } from "@leafygreen-ui/table";
import Checkbox from "@leafygreen-ui/checkbox";
import { FaCheckCircle, FaWindowClose, FaPassport, FaQrcode } from "react-icons/fa";
import moment from "moment";
import { GridLoader } from "react-spinners";
import { serializeError } from "serialize-error";
import ItemOptions from "utils/ItemOptions";
import Button from "@leafygreen-ui/button";
import { labelTemplateQR } from "utils/labelTemplates";
import ConfirmationModal from '@leafygreen-ui/confirmation-modal';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { errorHandler } from "utils/errorHandler";

const title = "Browsing an order item";
const socket = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT);

const fetchPackingItem = ({ queryKey }) => {
  console.log("* fetchPackingItem init");
  return new Promise(async (resolve, reject) => {
    try {
      // console.log("- queryKey: ", queryKey);
      let condition = queryKey[1];
      if (condition) {
        const response = await getPackingItemsByOrder({ ...condition });
        // const response = await getPackingItem({ condition });
        // console.log("- getPackingItem response: ", response);
        resolve(response[0]);
      } else {
        resolve(null);
      }
    } catch (error) {
      let retval = serializeError(error);
      reject(retval);
    }
  });
};

const wipStatues = [
  {code: 'press', name: 'Heat Press'},
  {code: 'emb', name: 'Embroidery'},
  {code: 'laser', name: 'Laser Engraving'},
]

const color = "#adadad";
const highlightColor = "#252525";

export default function BrowsingOrderItem({ location, history }) {
  const { settings, platens, hostname } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const [msg, setMsg] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [preSignedUrl, setPreSignedUrl] = useState(null);
  const [condition, setCondition] = useState(null);
  const [customPlaten, setCustomPlaten] = useState(null);
  const [loadedArtwork, setLoadedArtwork] = useState(false);
  const itemRef = useRef(null);
  const ssOrderRef = useRef(null);
  const selectedPositionRef = useRef(null);
  const settingsRef = useRef(null);
  const bannerVariant = useRef("info");
  const conditionRef = useRef(null);
  const graphicsRef = useRef(null);
  const [selectedWorkStatus, setSelectedWorkStatus] = useState(wipStatues[0])
  const [modalConfirmIsOpen, setModalConfirmIsOpen] = useState(false)

  const {
    data: item,
    refetch: refetchPackingItem,
    isLoading,
  } = useQuery(["item", condition], fetchPackingItem, {
    enabled: false,
    refetchOnWindowFocus: false,
  });

  const { data: ssOrder, isLoading: isLoadingSS, refetch: refetchSSOrder } = useQuery(
    ["ssOrder"],
    async () => {
      console.log("* refetchSSOrder init");
      // console.log("- conditionRef: ", conditionRef.current);
      let orderId = conditionRef.current?.orderId;
      console.log("- orderId: ", orderId);
      if (orderId) {
        return await getShipStationOrder(parseInt(orderId));
      }
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: itemDoc, refetch: refetchItemDoc } = useQuery(
    ["itemDoc", condition],
    async () => {
      console.log("* refetchItemDoc init");
      // console.log('- conditionRef: ', conditionRef.current)
      let sku = condition?.sku
        ? condition.sku
        : conditionRef.current?.sku ?? conditionRef.current.sku;
      // console.log("- sku: ", sku);
      if (sku) {
        return await getItemDoc(sku);
      }
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  const queryClient = useQueryClient();

  // console.log("- item", item);
  // console.log("- ssOrder", ssOrder);
  if (ssOrder) ssOrderRef.current = ssOrder;
  // console.log("- itemDoc", itemDoc);
  // console.log("- isLoading", isLoading);
  // console.log("- settings.autoQueue: ", settings);

  //onQueueSubscribeCallback
  const onQueueSubscribeCallback = useCallback(() => {
    socket.on("on-barcode-scanned", async (data) => {
      console.log(`* onQueueSubscribeCallback `);
      console.log("- data: ", data);

      toast.dismiss();
      queryClient.removeQueries('item')
      queryClient.removeQueries('ssOrder')
      queryClient.removeQueries('itemDoc')
      ssOrderRef.current = null
      itemRef.current = null;
      graphicsRef.current = null;

      setSelectedPosition(null);
      selectedPositionRef.current = null;
      bannerVariant.current = "info"

      if(settings) settingsRef.current = settings;

      let dataArr = [];
      let delimiter = ";";
      if (_.includes(data, "*")) delimiter = "*";
      console.log("- delimiter: ", delimiter);
      dataArr = data.split(delimiter);
      let sku = dataArr[0];
      let orderId = dataArr[1];
      let orderItemId = dataArr[2];
      let graphicPosition = dataArr[3];

      console.log("- sku", sku);
      console.log("- orderId", orderId, typeof orderId);
      console.log("- orderItemId", orderItemId, typeof orderItemId);
      console.log("- graphicPosition", graphicPosition);
      
      let condition = {sku};
      if (orderId) condition.orderId = orderId;
      if (orderItemId) condition.orderItemId = orderItemId;
      if (graphicPosition) condition.graphicPosition = graphicPosition;

      setMsg(null);
      setCondition({ ...condition });
      conditionRef.current = condition;
    });
    // eslint-disable-next-line
  }, [settings]);

  //onQueueSubscribeCallback
  useEffect(() => {
    // console.log("redner on QueueEpsonPrint");
    onQueueSubscribeCallback();

    return () => {
      // console.log("clean-up fn on QueueEpsonPrint");
      socket.removeAllListeners(["on-barcode-scanned"]);
    };
  }, [onQueueSubscribeCallback]);


  //refect by condition
  useEffect(() => {
    console.log("* condition hook init");
    console.log("- condition: ", condition);
    if (condition?.sku && condition?.orderId) {
      refetchPackingItem();
      // refetchSSOrder();
    }

    if (condition?.sku && !condition?.orderId) {
      refetchItemDoc();
    }

    // eslint-disable-next-line
  }, [condition]);

  //item & itemDoc hook
  useEffect(() => {
    console.log("* item & itemDoc hooks init");
    console.log("- item: ", item);
    console.log("- itemDoc: ", itemDoc);
    if (item || itemDoc) {
      itemRef.current = item || itemDoc;
      // console.log('- itemRef.current: ', itemRef.current)
      if (item && !itemDoc) queryClient.removeQueries("itemDoc");

      msg && setMsg(null);
      itemDoc && setMsg("The item information was only retrieved.");

      //graphics
      graphicsRef.current = itemRef.current?._item?._graphics
        ? itemRef.current._item._graphics
        : itemRef.current?._graphics ?? itemRef.current._graphics;
      console.log("- graphicsRef: ", graphicsRef.current);

      if (_.isArray(graphicsRef.current)) {
        if (!Boolean(graphicsRef.current?.length)) {
          setMsg("Graphic not defined.");
          return;
        } else {
          handleByGraphics(graphicsRef.current);
        }
      }

      // customPlaten
      let ripEnv = itemRef.current?._item?.ripEnv
        ? itemRef.current._item.ripEnv
        : itemRef.current?.ripEnv ?? itemRef.current.ripEnv;
      console.log("- ripEnv: ", ripEnv);
      if (ripEnv) {
        handleByRipEnv(ripEnv);
      }

      //checked-out?
      if (item) {
        let txt;
        if (itemRef.current?.checkedOutQty > 0) {
          txt = "Already checked-out before";
        } else if (
          !_.includes(itemRef.current?._order?.orderStatus, "awaiting")
        ) {
          txt = "Might be already a shipped item";
        }
        toast.dismiss();
        txt &&
          toast.info(txt, {
            position: "bottom-right",
            autoClose: 2000,
          });

        // call ssOrder if source is not manual order
        if(item?._order?.source !== 'manual') {
          refetchSSOrder()
        }  
      }

    } // end-if

    // eslint-disable-next-line
  }, [item, itemDoc]);

  useEffect(() => {
    console.log("* selectedPosition hook init");
    // console.log('- selectedPosition: ', selectedPosition);
    // console.log('- settingsRef: ', settingsRef.current);
    selectedPositionRef.current = selectedPosition && selectedPosition;
    if (selectedPositionRef.current) {
      // get mockup link
      let graphicFileName = selectedPosition?.graphicFileName 
        ? selectedPosition.graphicFileName
        : selectedPositionRef?.current?.graphicFileName 
        ?? selectedPositionRef.current.graphicFileName;
      console.log("- graphicFileName: ", graphicFileName);
      if (graphicFileName) {
        let { REACT_APP_SOCKET_ENDPOINT } = process.env;
        if (/^true$/.test(settingsRef.current?.useLocalRepository)) {
          setPreSignedUrl(
            `${REACT_APP_SOCKET_ENDPOINT}/graphics/${graphicFileName}`
          );
        } else {
          const key = `${
            settingsRef.current?.aws?.graphics || "graphics"
          }/${graphicFileName}`;
          getS3presign({ key }).then((response) => {
            // console.log("- getS3presign response:", response);
            setPreSignedUrl(() => response?.url);
          });
        }
      }

      //check ripEnv from selectedPositionRef
      if (selectedPositionRef.current?.ripEnv) {
        console.log(
          "- selectedPositionRef?.current?.ripEnv: ",
          selectedPositionRef.current.ripEnv
        );
        handleByRipEnv(selectedPositionRef.current.ripEnv);
      }

      // check media if not use default in media
      if(settingsRef.current && settingsRef.current?.defaultIntegratedAutomation.includes('CAD') && _.isNil(selectedPositionRef?.current?.media)) {
        const defaultMedia = _.find(settingsRef.current?.media, {isDefault: true});
        // console.log('- defaultMedia; ', defaultMedia)
        if(defaultMedia) {
          if(defaultMedia?.code) {
            setSelectedPosition(current => {
              // console.log('- current: ', current);
              let retVal = {...current, media: defaultMedia.code}
              selectedPositionRef.current = retVal;
              return retVal
            })
          } 
        } else {
          if(Boolean(settingsRef.current?.media?.length)) {
            setSelectedPosition(current => {
              // console.log('- current: ', current);
              let retVal = {...current, media: settingsRef.current?.media[0]?.code}
              selectedPositionRef.current = retVal;
              return retVal
            })
          }
            
        }
      }
    }

    // eslint-disable-next-line
  }, [selectedPosition, settings]);

  const handleByRipEnv = (ripEnv) => {
    console.log("* handleByRipEnv init");
    console.log("- ripEnv: ", ripEnv);
    // console.log("- settings.customPlaten: ", settingsRef.current);

    if (settingsRef?.current?.customPlaten) {
      getSetCustomPlaten(ripEnv);
    }
  };

  const handleByGraphics = (graphics) => {
    console.log("* handleByGraphics init");
    console.log("graphics: ", graphics, _.isArray(graphics))
    // setSelectedPosition
    if (graphics?.length === 1) {
      // console.log("- graphics[0]", graphics[0]);
      const graphic = graphics[0]

      if (graphics[0].graphicPosition.toLowerCase() !== "back") {
        setSelectedPosition(graphic);
        selectedPositionRef.current = graphic;
      } else {
        console.log('- conditionRef: ', conditionRef)
        if(conditionRef.current?.graphicPosition) {
          for(let g of graphics) {
            // console.log(g)
            if(g?.graphicPosition) {
              if(g.graphicPosition === conditionRef.current?.graphicPosition) {
                setSelectedPosition(g);
                selectedPositionRef.current = g;
                break;
              }
            }
          }
        }
      }
    }
  };

  const PlatenName = ({ platen }) => {
    const foundPlaten = _.find(platens, { code: platen });
    // console.log("foundPlaten", foundPlaten);
    return (
      <span className="content content-ref">
        {foundPlaten && `${foundPlaten.name}(${platen})`}
      </span>
    );
  };

  const MediaName = ({media, index}) => {
    // console.log('* MediaName init')
    // console.log('- media: ', media)
    // console.log('- index: ', index)
    // console.log('- selectedPositionRef: ', selectedPositionRef)
    return (
      <select
        name="media"
        value={media ? media : selectedPositionRef.current?.media ? selectedPositionRef.current.media : undefined}
        onChange={(e) => {
          graphicsRef.current[index] = {...graphicsRef.current[index], media: e.target.value}
          setSelectedPosition((current) => {
            selectedPositionRef.current = {...current, media: e.target.value}
            return {...current, media: e.target.value}
          })
          msg && setMsg(null)
        }}
      >
        <option value="">-- Choose media --</option>
        {settingsRef.current?.media &&
          settingsRef.current?.media.map((p) => {
            return (
              <option value={p.code} key={p.code}>
                {p.name} ({p.size.width}x{p.size.height} inch)
              </option>
            );
          })}
      </select>
    )
  }

  const Printed = ({ position }) => {
    // console.log("* Printed init");
    // console.log("- position: ", position);
    // console.log("- itemRef.current: ", itemRef.current);
    const foundPrinted = _.find(itemRef.current?.printed, (value) => {
      return _.includes(value.position.toLowerCase(), position.toLowerCase());
    });
    // console.log("- foundPrinted", foundPrinted);
    return <b className="printed-qty">{foundPrinted ? foundPrinted.qty : 0}</b>;
  };

  const PrintedStatus = ({ position }) => {
    // console.log("position on PrintedStatus", position);
    const foundPrinted = _.find(itemRef.current?.printed, (value) => {
      return _.includes(value.position.toLowerCase(), position.toLowerCase());
    });
    // console.log("foundPrinted", foundPrinted);
    return foundPrinted && foundPrinted.qty >= itemRef.current?.quantity ? (
      <FaCheckCircle />
    ) : (
      <FaWindowClose />
    );
  };

  const getSetCustomPlaten = (ripEnv) => {
    const regex = /\d+[x]\d+/gim;
    let isCustomPlaten = regex.test(ripEnv);
    console.log(`- isCustomPlaten: ${isCustomPlaten}`);
    if (isCustomPlaten) {
      let matched = ripEnv.match(regex);
      console.log("- matched", matched);
      if (Boolean(matched.length)) {
        toast.dismiss();
        let customPlaten = matched[0];
        console.log("- customPlaten: ", customPlaten);
        let platenName = `Custom Platen (${customPlaten})`;
        setCustomPlaten(platenName);

        toast.info(`${platenName} was applied`, {
          position: "bottom-right",
        });
      }
    }
  };

  const GraphicsTable = ({ graphics, useARXfile, selectedAutomation }) => {
    let index = 0;
    return (
      <div className="queue-print-graphics card mb-10">
        <Table
          data={graphics}
          columns={[
            <TableHeader label="Check position to print" />,
            <TableHeader label="Graphic File" />,
            <TableHeader
              label="ARX File"
              className={!useARXfile && "hidden"}
            />,
            <TableHeader label="Size(width/height)" />,
            <TableHeader label="Platen" className={_.includes(selectedAutomation, "CAD") && "hidden"}/>,
            <TableHeader label="Media(CADlink)" className={!_.includes(selectedAutomation, "CAD") && "hidden"} />,
            <TableHeader label="Rip Env." />,
            <TableHeader label="Printed" className={itemDoc && "hidden"} />,
            <TableHeader label="status" className={itemDoc && "hidden"} />,
          ]}
        >
          {({ datum }) => (
            <Row key={datum.graphicPosition}>
              <Cell>
                <Checkbox
                  label={datum.graphicPosition}
                  checked={
                    selectedPosition &&
                    selectedPosition.graphicPosition === datum.graphicPosition
                      ? true
                      : false
                  }
                  onChange={() => {
                    setSelectedPosition(datum);
                    selectedPositionRef.current = datum;
                  }}
                />
              </Cell>
              <Cell>{datum?.graphicFileName ? datum.graphicFileName : ""}</Cell>
              <Cell className={!useARXfile && "hidden"}>
                {datum.arxFileName ? datum.arxFileName : ""}
              </Cell>
              <Cell>
                <input
                    type="text"
                    id="width"
                    defaultValue={
                      _.has(datum, "size") &&
                      !_.isNil(datum.size.width)
                        ? datum.size.width
                        : ""
                    }
                    style={{ maxWidth: 60 }}
                    placeholder="width"
                    disabled={true}
                  />
                  <input
                    type="text"
                    id="height"
                    defaultValue={
                      _.has(datum, "size") &&
                      !_.isNil(datum.size.height)
                        ? datum.size.height
                        : ""
                    }
                    style={{ maxWidth: 60, marginLeft: 10 }}
                    placeholder="height"
                    disabled={true}
                  />
              </Cell>
              { !_.includes(selectedAutomation, 'CAD') 
                ? (
                <Cell>
                  {customPlaten ? (
                    <span>{customPlaten}</span>
                  ) : (
                    <PlatenName platen={datum.platen} />
                  )}
                </Cell>

                ) : (
                <Cell>
                  <MediaName media={datum?.media} index={index} />
                </Cell>
                )
              }
              <Cell>{datum?.ripEnv ? datum.ripEnv : ""}</Cell>
              <Cell className={itemDoc && "hidden"}>
                <Printed position={datum.graphicPosition} />
              </Cell>
              <Cell className={itemDoc && "hidden"}>
                <PrintedStatus position={datum.graphicPosition} />
              </Cell>
              {index = index + 1}

            </Row>
          )}
        </Table>
      </div>
    );
  };

  const WipStatuesTable = ({data}) => {

    return (
      <div className="wip-status card mt-10 p-10">
        <h5>Work in process status</h5>

        <Table
          data={data}
          columns={[
            <TableHeader label="Name" />,
            <TableHeader label="Date" />,
            <TableHeader label="Quantity" />,
            <TableHeader label="Hostname" />,
            <TableHeader label="Username" />,
          ]}
        >
        {({ datum }) => (
            <Row key={datum._id}>
              <Cell>{datum.name}</Cell>
              <Cell>{moment(datum.date).local().format("MM-DD-YYYY h:mm a")}</Cell>
              <Cell>{datum.qty}</Cell>
              <Cell>{datum.hostname}</Cell>
              <Cell>{datum.username}</Cell>
            </Row>  
        )}
        </Table>  
      </div>
    )
  }

  const updateWorkStatus = useMutation(
    async () => {
      console.log("* updateOrderItem mutate init");
      console.log('- selectedWorkStatus: ', selectedWorkStatus)
      return await updatePackingItemWorkStatus({
        condition: { _id: itemRef.current._id, "workInProcessStatus.code":  selectedWorkStatus.code},
        update: {
          $set: {
            "workInProcessStatus.$.code": selectedWorkStatus.code,
            "workInProcessStatus.$.name": selectedWorkStatus.name,
            "workInProcessStatus.$.username": user?.username,
            "workInProcessStatus.$.date": new Date(),
            "workInProcessStatus.$.hostname": hostname,
            "workInProcessStatus.$.qty": itemRef.current?.quantity
          }
        },
      });
    },
    {
      onError: (err) => {
        console.log("- onError, err", err);
      },
      onSuccess: async (data) => {
        console.log("- onSuccess data: ", data);
        queryClient.setQueryData(['item', condition], (old) => ({
          ...old,
          workInProcessStatus: data?.workInProcessStatus
        }))
        setModalConfirmIsOpen(true)
      },
    }
  );

  const handlePrintLabel = async () => {
    console.log("* handlePrintLabel init");
    // console.log("- item: ", item);
    // console.log("- selectedPositionRef: ", selectedPositionRef.current);
    // console.log("- settings?.printLabelByGraphicPositions: ", settings.printLabelByGraphicPositions);
    try {
      let retValLabel;
      if (!settings.printLabelByGraphicPositions) {
        if(item?._item?.graphicPosition !== item._item._graphics[0]?.graphicPosition) {
          item._item.graphicPosition = item._item._graphics[0]?.graphicPosition;
        }
        retValLabel = labelTemplateQR({ item, platens, settings, media: selectedPositionRef.current?.media });
        retValLabel?.zpl && socket.emit("on-sku-print", retValLabel.zpl);
      } else {
        for (let graphic of item._item._graphics) {
          // console.log('- graphic: ', graphic)
          if (item?._item?.graphicPosition)
            item._item.graphicPosition = graphic.graphicPosition;
          retValLabel = labelTemplateQR({ item, platens, settings, media: selectedPositionRef.current?.media });
          retValLabel?.zpl && socket.emit("on-sku-print", retValLabel.zpl);
        }
      }
    } catch (err) {
      const retVal = errorHandler(err)
      console.error("- handlePrintLabel err: ", retVal);
      setMsg(retVal);
    }
    setModalConfirmIsOpen(false);

  };

  return (
    <>
      <Title title={title} />
      <PageMainHeader title={title} user={user} settings={settings} />
      <ToastContainer
        theme="colored"
        pauseOnHover={false}
        pauseOnFocusLoss={false}
      />
      <section className="primary queue-print">
        <div className="queue-print-info card mb-10">
          <div className="title-content-list">
            <div className="title-content">
              <span className="title">SKU</span>
              <span className="d-block content">
                {item ? item?.sku : itemDoc && itemDoc?.sku}
              </span>
            </div>
            <div className="title-content">
              <span className="title">Description</span>
              <span>{item ? item?.name : itemDoc && itemDoc?.description}</span>
            </div>
            {item && (
              <>
                <div className="title-content">
                  <span className="title">Order Quantity</span>
                  <span >{item && item?.quantity}</span>
                </div>
                <div className="title-content">
                  <span className="title">Order No.</span>
                  <Link to={`workorder-details/${item && item?.orderId}`}>
                    <span>{item && item?.orderNumber}</span>
                  </Link>
                </div>

                <div className="title-content">
                  <span className="title">Order Date</span>
                  <span>
                    {item &&
                      moment(item?._order?.orderDate)
                        .local()
                        .format("MM-DD-YYYY")}
                  </span>
                </div>
              </>
            )}
            <div className="title-content">
              <span className="title">Component SKU</span>
              <span>
                {item ? item?._item?.component : itemDoc && itemDoc.component}
              </span>
            </div>
            <div className="title-content">
              <span className="title">Rip Profile</span>
              <span>
                {item ? item?._item?.ripEnv : itemDoc && itemDoc.ripEnv}
              </span>
            </div>
            <div className="title-content">
              <span className="title">Position</span>
              <span>
                {item
                  ? item?._item?.graphicPosition
                  : itemDoc && itemDoc.graphicPosition}
              </span>
            </div>
            <div className="title-content">
              <span className="title">Customer Notes</span>
              {!isLoadingSS ? (
                <span className="font-size-sm">
                  {ssOrderRef.current?.customerNotes}
                </span>
              ) : (
                <SkeletonTheme color={color} highlightColor={highlightColor}>
                  <Skeleton width={150} height={30} duration={1} />
                </SkeletonTheme>
              )}
              
            </div>

            <div className="title-content">
              <span className="title">Internal Notes</span>
              {!isLoadingSS ? (
                <span className="font-size-sm">
                  {ssOrderRef.current?.internalNotes}
                </span>
              ) : (
                <SkeletonTheme color={color} highlightColor={highlightColor}>
                  <Skeleton width={150} height={30} duration={1} />
                </SkeletonTheme>
              )}
              
            </div>
            {item && Boolean(item?.options?.length) && (
              <div className="title-content">
                <span className="title">Order Item Options</span>
                <ItemOptions options={item.options} />
              </div>
            )}
          </div>
          <div className="queue-print-img markup">
            <img
              src={
                !_.isNil(selectedPosition)
                  ? !_.isEmpty(selectedPosition.imageUrl)
                    ? selectedPosition.imageUrl
                    : item
                    ? item?._item?.imageUrl
                    : itemDoc && itemDoc?.imageUrl
                  : item
                  ? item?._item?.imageUrl
                  : itemDoc && itemDoc?.imageUrl
              }
              className="responsive-img graphic-image-url"
              alt={item ? item?.sku : itemDoc && itemDoc?.sku}
              onError={(e) =>
                (e.target.src = `${process.env.REACT_APP_S3_NOIMG}/300.png`)
              }
            />
          </div>
          <div className="queue-print-img graphic">
            {selectedPosition && preSignedUrl && (
              <>
                <img
                  src={`${preSignedUrl}`}
                  className="responsive-img graphic-file"
                  alt={selectedPosition.graphicFileName}
                  key={preSignedUrl}
                  onError={(e) =>
                    (e.target.src = `${process.env.REACT_APP_S3_NOIMG}/300.png`)
                  }
                  onLoad={() => {
                    setLoadedArtwork(true);
                  }}
                  style={{ display: !loadedArtwork ? "none" : "block" }}
                />
                {!loadedArtwork && <GridLoader color={"#09804C"} />}
              </>
            )}
          </div>
        </div>

        {graphicsRef.current && (
          <GraphicsTable
            graphics={graphicsRef.current}
            useARXfile={settingsRef.current?.useARXfile}
            selectedAutomation={settingsRef.current?.defaultIntegratedAutomation}
          />
        )}

        {msg && (
          <Banner variant={bannerVariant.current} className="mb-10">
            {msg}
          </Banner>
        )}

        <div className="d-flex justify-content-space">
          <Button
            onClick={handlePrintLabel}
            leftGlyph={<FaQrcode />}
            variant="primary"
            size="large"
          >
            Print Label
          </Button>
          <div className="d-flex justify-content-end">
            <div className="input-field">
              <label style={{marginBottom: 0, fontWeight: 'normal'}}>Work-in-process</label>
              <select style={{minWidth: 200}} 
                onChange={(e) => {
                  let value = e.target.value;
                  setSelectedWorkStatus(() => {
                    const found = _.find(wipStatues, {code: value})
                    return found;
                  })
                }}
              >
                {wipStatues.map(s => {
                  return(
                    <option value={s.code} key={s.code}>{s.name}</option>
                  )
                })}
              </select>

            </div>
            <Button
              className="ml-10"
              variant="primary"
              onClick={() => updateWorkStatus.mutate()}
              disabled={false}
              leftGlyph={<FaPassport />}
              size="large"
            >
              QC Pass
            </Button>
          </div>
        </div>


        {itemRef.current && Boolean(itemRef.current?.workInProcessStatus?.length) && (
          <WipStatuesTable data={itemRef.current.workInProcessStatus} />
        )}

        <ConfirmationModal
          open={modalConfirmIsOpen}
          onConfirm={handlePrintLabel}
          onCancel={() => setModalConfirmIsOpen(false)}
          buttonText='Confirm'
          title="Confirm printing labels"
        >
          Click confirm to print labels or cancel to close
        </ConfirmationModal>
        <ModalPreloader modalPreloaderIsOpen={isLoading} />
        {/* <pre>selectedWorkStatus: {JSON.stringify(selectedWorkStatus, null, 2)}</pre> */}
        {/* <pre>selectedPositionRef.current {selectedPositionRef.current && JSON.stringify(selectedPositionRef.current, null, 2)}</pre>   */}
        {/* <pre>itemRef.current {JSON.stringify(itemRef.current, null, 2)}</pre>   */}
      </section>
    </>
  );
}
